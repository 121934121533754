<div [formGroup]="form()">
  <mat-checkbox formControlName="freeTrial">Free Trial</mat-checkbox>

  @if (form().value.freeTrial) {
    <mat-form-field class="end-date" appearance="fill" floatLabel="always">
      <mat-label>Free Trial End Date</mat-label>
      <input
        matInput
        [matDatepicker]="freeTrialDatePicker"
        formControlName="freeTrialExpirationDate"
      />
      <mat-datepicker #freeTrialDatePicker />
      <mat-datepicker-toggle matSuffix [for]="freeTrialDatePicker" />
    </mat-form-field>
  }
</div>
