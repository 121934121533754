import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

interface FileDropConfirmConfig {
  title: string;
}
@Component({
  standalone: true,
  imports: [MatDialogModule],
  template: `
    <h2>{{ config.title }}</h2>

    <mat-dialog-actions class="flex -h-end">
      <button mat-dialog-close class="button -small" tabindex="2">No</button>
      <button
        class="-danger margin-left-small button -small"
        mat-dialog-close="'yes'"
        tabindex="1"
        cdkFocusRegionstart
      >
        Yes
      </button>
    </mat-dialog-actions>
  `,
})
export class FileDropModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public config: FileDropConfirmConfig
  ) {}
}
