import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from '@core/services/breadcrumbs.service';
import { CwsService } from '@core/services/cws.service';
import { MATERIAL_MODULES } from '@shared/material-design/material-modules';
import { NotificationService } from '@shared/notification/notification.service';
import { CohortInfoComponent } from '@views/feature-flags/cohort-info/cohort-info.component';
import { UserAutocompleteSelectboxComponent } from '@views/feature-flags/user-autocomplete-selectbox/user-autocomplete-selectbox.component';
import { ConfirmDialogComponent } from '@views/partials/confirm-dialog/confirm-dialog.component';
import { keyBy } from 'lodash-es';
import { combineLatest, lastValueFrom } from 'rxjs';

import { ElementId, UserWildcardComparator } from '@storykit/constants';
import {
  FeatureFlagCohortList,
  UpdateFeatureFlagBody,
} from '@storykit/typings/src/cws/feature-flag';

interface FormType {
  key: FormControl<string>;
  description: FormControl<string | null>;
  active: FormControl<boolean>;
  users: FormArray<
    FormGroup<{
      idOrEmail: FormControl<boolean>;
      active: FormControl<boolean>;
      id: FormControl<string>;
      email: FormControl<string>;
      comparator: FormControl<string>;
    }>
  >;
  cohorts: FormArray<
    FormGroup<{
      _id: FormControl<string>;
      active: FormControl<boolean>;
    }>
  >;
}

@Component({
  selector: 'app-feature-flag-form',
  standalone: true,
  imports: [
    MATERIAL_MODULES,
    FormsModule,
    ReactiveFormsModule,
    CohortInfoComponent,
    UserAutocompleteSelectboxComponent,
  ],
  templateUrl: './feature-flag-form.component.html',
  styleUrl: './feature-flag-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureFlagFormComponent implements OnInit {
  form: FormGroup<FormType> | null = null;
  cohortMap: Record<string, FeatureFlagCohortList['cohorts'][0]> = {};
  cohorts: FeatureFlagCohortList['cohorts'][0][] = [];
  error: string | null = null;
  elementId = ElementId;

  usersDataSource: FormType['users']['value'] = [];
  displayedColumns = ['active', 'idOrEmail', 'input', 'actions'];

  constructor(
    private formBuilder: FormBuilder,
    private cwsService: CwsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private breadcrumbsService: BreadcrumbsService,
    private notification: NotificationService,
    private ngZone: NgZone
  ) {
    this.breadcrumbsService.set([
      { label: 'Feature flags', link: '/feature-flags' },
      { label: 'Feature flag' },
    ]);
  }

  ngOnInit() {
    const flagId = this.route.snapshot.paramMap.get('featureFlagId');
    if (flagId) {
      void lastValueFrom(
        combineLatest([
          this.cwsService.getFeatureFlag(flagId),
          this.cwsService.getCohorts(),
        ])
      )
        .then(([featureFlag, { cohorts }]) => {
          this.cohortMap = keyBy(cohorts, '_id');
          this.cohorts = cohorts;
          const control = this.formBuilder.nonNullable.control.bind(
            this.formBuilder.nonNullable.control
          );
          this.form = this.formBuilder.nonNullable.group<FormType>({
            key: control(featureFlag.key, Validators.required),
            description: control(featureFlag.description ?? ''),
            active: control(featureFlag.active),
            users: this.formBuilder.nonNullable.array(
              (featureFlag.users || []).map((user) =>
                this.formBuilder.nonNullable.group({
                  idOrEmail: control(!!user.user.email),
                  active: control(user.active || false),
                  id: control(user.user.id ?? ''),
                  email: control(user.user.email?.text ?? ''),
                  comparator: control(
                    (user.user.email?.comparator ??
                      UserWildcardComparator.EXACT) as string,
                    Validators.required
                  ),
                })
              )
            ),
            cohorts: this.formBuilder.nonNullable.array(
              (featureFlag.cohorts || []).map((cohort) =>
                this.formBuilder.nonNullable.group({
                  _id: control(cohort._id),
                  active: control(cohort.active ?? false),
                })
              )
            ),
          });

          this.updateUsersDataSource();
        })
        .catch((error: Error) => {
          this.error = error.toString();
        });
    } else {
      this.error = 'No feature flag id';
    }
  }

  private updateUsersDataSource(): void {
    const usersArray = this.form?.get('users') as FormType['users'];
    this.usersDataSource = usersArray ? usersArray.value : [];
    this.cdr.markForCheck();
  }

  addUser() {
    const usersArray = this.form?.get('users') as FormType['users'];
    usersArray.push(
      this.formBuilder.nonNullable.group({
        idOrEmail: [true, Validators.required],
        active: [false, Validators.required],
        id: [''],
        email: [''],
        comparator: [
          UserWildcardComparator.EXACT as string,
          Validators.required,
        ],
      })
    );
    this.updateUsersDataSource();
  }

  removeUser(index: number) {
    const usersArray = this.form?.get('users') as FormType['users'];
    usersArray.removeAt(index);
    this.updateUsersDataSource();
  }

  addCohort(cohortId: string) {
    if (!cohortId) {
      return;
    }
    const cohortsArray = this.form?.get('cohorts') as FormType['cohorts'];
    if (cohortsArray?.value?.find((cohort) => cohort._id === cohortId)) {
      return;
    }
    cohortsArray.push(
      this.formBuilder.nonNullable.group({
        _id: [cohortId],
        active: [false],
      })
    );
  }

  removeCohort(index: number) {
    const cohortsArray = this.form?.get('cohorts') as FormType['cohorts'];
    cohortsArray.removeAt(index);
  }

  async deleteFeatureFlag() {
    const flagId = this.route.snapshot.paramMap.get('featureFlagId');
    if (!flagId) {
      return;
    }
    const confirmed = (await lastValueFrom(
      ConfirmDialogComponent.open(this.dialog, {
        action: 'Delete',
        subject: 'feature flag',
      }).afterClosed()
    )) as boolean;

    if (!confirmed) {
      return;
    }

    await lastValueFrom(this.cwsService.deleteFeatureFlag(flagId));
    void this.router.navigate(['/feature-flags']);
  }

  async save() {
    const flagId = this.route.snapshot.paramMap.get('featureFlagId');
    if (!flagId || !this.form) {
      return;
    }

    const formValue = this.form.value;

    if (!formValue.key) {
      return;
    }

    const featureFlag: UpdateFeatureFlagBody = {
      _id: flagId,
      key: formValue.key,
      description: formValue.description ?? '',
      active: formValue.active ?? false,
      users: (formValue.users ?? []).map((user) => ({
        user: {
          id: user.idOrEmail ? undefined : user.id,
          email: user.idOrEmail
            ? {
                text: user.email ?? '',
                comparator:
                  user.comparator === UserWildcardComparator.REGEX
                    ? UserWildcardComparator.REGEX
                    : UserWildcardComparator.EXACT,
              }
            : undefined,
        },
        active: user.active ?? false,
      })),
      cohorts: (formValue.cohorts ?? []).map((cohort) => ({
        cohortId: cohort._id ?? '',
        active: cohort.active ?? false,
      })),
    };

    await lastValueFrom(this.cwsService.saveFeatureFlag(flagId, featureFlag));
    this.notification.show('success', 'Feature flag saved');
  }

  getCohortsFormActiveField(index: number): FormControl<boolean> {
    const control = (this.form?.get('cohorts') as FormType['cohorts'])
      ?.at(index)
      .get('active');

    if (!control) {
      throw new Error('Failed to get active field');
    }

    return control as FormControl<boolean>;
  }

  isUserIdOrEmail(i: number) {
    const users = this.form?.get('users') as FormType['users'];
    if (!users?.length) {
      return false;
    }
    return users.at(i)?.get('idOrEmail')?.value;
  }

  dropCohort(event: CdkDragDrop<string[]>) {
    const cohortsArray = this.form?.get('cohorts') as FormType['cohorts'];
    moveItemInArray(
      cohortsArray.controls,
      event.previousIndex,
      event.currentIndex
    );
    this.cdr.markForCheck();
    // what a beautiful hack
    // we can not update the data source in the same tick
    // because data has not been updated yet
    this.ngZone.runOutsideAngular(() => {
      void Promise.resolve().then(() => {
        this.ngZone.run(() => {
          this.updateUsersDataSource();
        });
      });
    });
  }
}
