@if (content) {
  <div class="input-to-clipboard">
    <mat-label class="form-label">{{ label }}</mat-label>
    <div class="copy-to-clipboard-input-button">
      <mat-form-field subscriptSizing="dynamic">
        <input type="text" matInput readonly value="{{ content }}" />
      </mat-form-field>
      <button
        (click)="copyToClipboard()"
        [skElementId]="elementId.Admin.UserPasswordResetLinkField"
        aria-label="Copy to clipboard"
      >
        📋
      </button>
    </div>
  </div>
}
