import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { environment } from '@environment';
import { SlideDefinition, SlideDefinitionType } from '@models/slide-definition';
import { SlideDefinitionListQuery } from '@models/slide-definition-filter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TOTAL_HEADER } from '@storykit/constants';
import { Cws } from '@storykit/typings';

interface ListResponse {
  slideDefinitions: SlideDefinition[];
  totalCount: number;
  pageSize: number;
}

@Injectable({
  providedIn: 'root',
})
export class SlideDefinitionService {
  constructor(private apiService: ApiService) {}

  getSlideDefinitions(
    slideDefinitionListQuery: Partial<SlideDefinitionListQuery> = {}
  ): Observable<ListResponse> {
    const params = this.getSlideDefinitionListQueryParams(
      slideDefinitionListQuery
    );
    return this.apiService
      .call<Cws.GetSlideDefinitions>({
        origin: environment.api.cws.endpoint,
        path: '/videostudio/definitions',
        query: params,
        params: {},
        body: null,
        method: 'GET',
      })
      .pipe(
        map((response) => {
          const slideDefinitions = response.body ?? [];

          return {
            slideDefinitions,
            totalCount: this.getTotalCount(response),
            pageSize:
              slideDefinitionListQuery.pageSize ?? slideDefinitions.length,
          };
        })
      );
  }

  getSlideDefinition(id: string): Observable<SlideDefinition> {
    return this.apiService
      .call<Cws.GetSlideDefinition>({
        origin: environment.api.cws.endpoint,
        path: '/videostudio/definition/:cwId',
        params: { cwId: id },
        query: {},
        body: null,
        method: 'GET',
      })
      .pipe(map((response) => response.body));
  }

  getSlideDefinitionGroups(): Observable<
    Cws.GetAllGroupsAndDefinitions['response']
  > {
    return this.apiService
      .call<Cws.GetAllGroupsAndDefinitions>({
        origin: environment.api.cws.endpoint,
        path: '/videostudio/definition/group',
        query: {
          lean: true,
        },
        params: {},
        body: null,
        method: 'GET',
      })
      .pipe(map(({ body }) => body));
  }

  getSlideDefinitionTypes(): Observable<SlideDefinitionType[]> {
    return this.apiService
      .call<Cws.GetSlideDefinitionTypes>({
        origin: environment.api.cws.endpoint,
        path: '/videostudio/definitionType',
        params: {},
        query: {},
        body: null,
        method: 'GET',
      })
      .pipe(map((response) => response.body));
  }

  createSlideDefinitionType(name: string): Observable<SlideDefinitionType> {
    return this.apiService
      .call<Cws.CreateSlideDefinitionType>({
        origin: environment.api.cws.endpoint,
        path: '/videostudio/definitionType',
        params: {},
        query: {},
        body: { name },
        method: 'POST',
      })
      .pipe(map((response) => response.body));
  }

  createSlideDefinition(
    slideDefinition: Omit<SlideDefinition, '_id'>
  ): Observable<SlideDefinition> {
    return this.apiService
      .call<Cws.CreateSlideDefinition>({
        origin: environment.api.cws.endpoint,
        path: '/videostudio/definition',
        params: {},
        query: {},
        body: slideDefinition,
        method: 'POST',
      })
      .pipe(map((response) => response.body));
  }

  updateSlideDefinition({
    _id,
    ...slideDefinition
  }: SlideDefinition): Observable<SlideDefinition> {
    return this.apiService
      .call<Cws.UpdateSlideDefinition>({
        origin: environment.api.cws.endpoint,
        path: '/videostudio/definition/:cwId',
        params: { cwId: _id },
        query: {},
        body: slideDefinition,
        method: 'PUT',
      })
      .pipe(map((response) => response.body));
  }

  deleteSlideDefinition(id: string): Observable<string> {
    return this.apiService
      .call<Cws.DeleteDefinition>({
        origin: environment.api.cws.endpoint,
        path: '/videostudio/definition/:definitionId',
        method: 'DELETE',
        query: {},
        params: { definitionId: id },
        body: null,
      })
      .pipe(map((response) => response.body.status));
  }

  private getSlideDefinitionListQueryParams({
    pageIndex,
    pageSize,
    search,
    accessCategory,
    garboSceneLocation,
    status,
  }: Partial<SlideDefinitionListQuery> = {}): Cws.GetSlideDefinitions['query'] {
    const params: Record<string, string> = {
      orderBy: 'displayName',
      order: 'asc',
      status: 'newest',
    };
    if (accessCategory !== undefined && accessCategory !== '') {
      params.accessCategory = String(accessCategory);
    }

    if (status !== undefined && status !== '') {
      params.status = status;
    }

    if (garboSceneLocation !== undefined) {
      params.garboSceneLocation = String(garboSceneLocation);
    }

    if (pageIndex !== undefined) {
      params.pageIndex = String(pageIndex);
    }

    if (pageSize !== undefined) {
      params.pageSize = String(pageSize);
    }

    if (search !== undefined && search !== '') {
      params.search = search;
    }
    return params;
  }

  private getHttpParams({
    pageIndex,
    pageSize,
    search,
    accessCategory,
    garboSceneLocation,
  }: Partial<SlideDefinitionListQuery> = {}) {
    let params = new HttpParams().appendAll({
      orderBy: 'displayName',
      order: 'asc',
      status: 'newest',
    });

    if (accessCategory !== undefined && accessCategory !== '') {
      params = params.set('accessCategory', String(accessCategory));
    }

    if (garboSceneLocation !== undefined) {
      params = params.set('garboSceneLocation', String(garboSceneLocation));
    }

    if (pageIndex !== undefined) {
      params = params.append('page', String(pageIndex));
    }

    if (pageSize !== undefined) {
      params = params.append('limit', String(pageSize));
    }

    if (search !== undefined && search !== '') {
      params = params.set('search', search);
    }

    return params;
  }

  private getTotalCount({ headers }: { headers: HttpHeaders }) {
    return headers.get(TOTAL_HEADER) ? Number(headers.get(TOTAL_HEADER)) : 0;
  }
}
