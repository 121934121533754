import { AsyncPipe, DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Injector,
  OnInit,
  signal,
} from '@angular/core';
import {
  takeUntilDestroyed,
  toObservable,
  toSignal,
} from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatButton, MatIconAnchor } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatActionList, MatListItem } from '@angular/material/list';
import { MatOption, MatSelect } from '@angular/material/select';
import {
  MatSidenav,
  MatSidenavContainer,
  MatSidenavContent,
} from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureService } from '@core/feature/feature.service';
import { AgencyService } from '@core/services/agency.service';
import { BreadcrumbsService } from '@core/services/breadcrumbs.service';
import { ClientService } from '@core/services/client.service';
import { CwsService } from '@core/services/cws.service';
import { ObservableCacheService } from '@core/services/observable-cache.service';
import { environment } from '@environment';
import { Agency } from '@models/agency';
import { Client } from '@models/client';
import { Modes } from '@models/modes';
import { ErrorComponent } from '@shared/error/error.component';
import { AgencyNotification } from '@shared/notification/agency-messages';
import { NotificationService } from '@shared/notification/notification.service';
import { UserListComponent } from '@shared/user-list/user-list.component';
import { AgencyClientsComponent } from '@views/agency/agency/agency-clients/agency-clients.component';
import { FeedsComponent } from '@views/agency/agency/feeds/feeds.component';
import { FontCalcListComponent } from '@views/agency/agency/font-calc-list/font-calc-list.component';
import {
  FreeTrialForm,
  FreeTrialFormComponent,
} from '@views/agency/agency/free-trial-form/free-trial-form.component';
import { PremiumFeaturesFormComponent } from '@views/agency/agency/premium-features-form/premium-features-form.component';
import { ConfirmDialogComponent } from '@views/partials/confirm-dialog/confirm-dialog.component';
import { UserListDataSource } from '@views/user/user-list/user-list.dataSource';
import {
  Observable,
  combineLatest,
  count,
  firstValueFrom,
  forkJoin,
  from,
  lastValueFrom,
  of,
} from 'rxjs';
import {
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';

import {
  CustomerType as CustomerTypes,
  ElementId,
  ElementIdType,
  FEATURE,
} from '@storykit/constants';
import { ElementIdDirective } from '@storykit/ui-components';

import IClient = kit.IClient;

const DEFAULT_USERS_AMOUNT_PER_CUSTOMER_TYPE = {
  [CustomerTypes.LITE]: 1,
  [CustomerTypes.PRO]: 2,
  [CustomerTypes.ENTERPRISE]: 9999,
  /** @deprecated - starter is deprecated */
  [CustomerTypes.STARTER]: 2,
  /** @deprecated - standard is deprecated */
  [CustomerTypes.STANDARD]: 5,
};

const DEFAULT_CUSTOMER_ADMIN_PER_CUSTOMER_TYPE = {
  [CustomerTypes.LITE]: true,
  [CustomerTypes.PRO]: true,
  [CustomerTypes.ENTERPRISE]: false,
  /** @deprecated - starter is deprecated */
  [CustomerTypes.STARTER]: true,
  /** @deprecated - standard is deprecated */
  [CustomerTypes.STANDARD]: true,
};

@Component({
  selector: 'app-agency',
  templateUrl: './agency.component.html',
  styleUrls: [
    './agency.component.scss',
    '../../../../styles/settings-form.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AsyncPipe,
    DatePipe,
    MatIconAnchor,
    MatLabel,
    MatFormField,
    MatInput,
    MatCheckbox,
    MatSelect,
    MatOption,
    MatButton,
    MatSidenavContent,
    MatSidenav,
    MatSidenavContainer,
    MatListItem,
    MatActionList,
    ElementIdDirective,
    ErrorComponent,
    AgencyClientsComponent,
    PremiumFeaturesFormComponent,
    FreeTrialFormComponent,
    FontCalcListComponent,
    UserListComponent,
    FeedsComponent,
  ],
})
export class AgencyComponent implements OnInit {
  readonly createUrl = environment.create.link;

  public e2e: { [_key in keyof typeof ElementId.Admin]: ElementIdType } =
    ElementId.Admin;

  customerTypeOptions: kit.CustomerType[] = [
    CustomerTypes.PRO,
    CustomerTypes.ENTERPRISE,
    CustomerTypes.LITE,
  ];

  modes = Modes;

  agencyLink = '';

  hasUnsavedClientChanges = false;
  hasUnsavedPremiumChanges = false;
  hasUnsavedFreeTrialChanges = false;
  hasUnsavedFontChanges = false;
  hasUnsavedFormChanges = false;

  formLoading = false;

  hasExpiredTrial = false;

  usersDataSource = new UserListDataSource(this.cwsService);

  mode = toSignal<Modes>(
    this.route.data.pipe(map(({ mode }) => mode as Modes)),
    {
      requireSync: true,
    }
  );

  agencyId$ = this.route.params.pipe(
    map(({ agencyId }) => (agencyId ? String(agencyId) : undefined))
  );

  agency$ = this.agencyId$.pipe(
    switchMap((agencyId) =>
      agencyId ? this.agencyService.getAgency(agencyId) : of(undefined)
    ),
    shareReplay(1)
  );

  loading$ = this.agency$.pipe(
    map(() => false),
    startWith(true)
  );

  fonts$ = this.agencyId$.pipe(
    switchMap((agencyId) =>
      agencyId ? this.cwsService.listFonts(agencyId) : of(undefined)
    ),
    shareReplay(1)
  );

  agencyFeatureSettingsFormGroup = this.formBuilder.group({
    videoblocks: this.formBuilder.group({
      value: [
        {
          value: true,
          disabled: true,
        },
        Validators.required,
      ],
    }),
    getty: this.formBuilder.group({
      value: [
        {
          value: false,
          disabled: false,
        },
        Validators.required,
      ],
    }),
    premiumMusic: this.formBuilder.group({
      value: [
        {
          value: true,
          disabled: false,
        },
        Validators.required,
      ],
    }),
    scriptCreators: this.formBuilder.group({
      value: [
        {
          value: true,
          disabled: false,
        },
        Validators.required,
      ],
    }),
    maxUsersAmount: this.formBuilder.group({
      value: [
        {
          value: DEFAULT_USERS_AMOUNT_PER_CUSTOMER_TYPE[CustomerTypes.PRO],
          disabled: false,
        },
      ],
    }),
    customerAdmin: this.formBuilder.group({
      value: DEFAULT_CUSTOMER_ADMIN_PER_CUSTOMER_TYPE[CustomerTypes.LITE],
      disabled: false,
    }),
    ruleSets: this.formBuilder.group({
      value: [
        {
          value: true,
          disabled: true,
        },
        Validators.required,
      ],
    }),
  });
  agencyForm = this.formBuilder.group(
    {
      name: ['', Validators.required],
      hubspotCompanyId: ['', [Validators.required]],
      active: [true, Validators.required],
      customerType: [CustomerTypes.PRO, Validators.required],
      freeTrial: [false, Validators.required],
      freeTrialExpirationDate: [null],
      featureSettings: this.agencyFeatureSettingsFormGroup,
      settings: this.formBuilder.group({
        scriptCreatorLanguageInstruction: [''],
        customTalkingHeadSlide: [''],
      }),
    },
    {
      validators: [this.freeTrialExpirationDateValidator],
    }
  );

  canEditLanguageInstruction() {
    return this.featureService.isFeatureEnabled(
      FEATURE.CAN_EDIT_LANGUAGE_INSTRUCTION
    );
  }
  canAdminFeeds() {
    return this.featureService.isFeatureEnabled(FEATURE.CAN_ADMIN_FEEDS);
  }

  private breadcrumbs$ = combineLatest([
    this.agency$,
    toObservable(this.mode),
  ]).pipe(
    map(([agency, mode]) => {
      const name = mode === Modes.Add ? 'New Agency' : agency?.name || '';
      return [{ label: 'Agencies', link: '/agencies' }, { label: name }];
    }),
    startWith([{ label: 'Agencies', link: '/agencies' }, { label: '' }])
  );

  currentClients = signal<Client[]>([]);

  clientsToCreate: Partial<IClient>[] = [];
  clientsToUpdate: Required<IClient>[] = [];
  clientsToDelete: string[] = [];

  activeTab = signal<
    | 'clients'
    | 'premium-features'
    | 'free-trial'
    | 'fonts'
    | 'users'
    | 'script-creators'
    | 'custom-talking-head-slide'
    | 'feeds'
  >(this.mode() === Modes.Edit ? 'clients' : 'premium-features');

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private agencyService: AgencyService,
    private breadcrumbsService: BreadcrumbsService,
    private formBuilder: FormBuilder,
    private notification: NotificationService,
    private dialog: MatDialog,
    private clientService: ClientService,
    private cache: ObservableCacheService,
    private cwsService: CwsService,
    private featureService: FeatureService,
    private destroyRef: DestroyRef,
    private injector: Injector
  ) {
    this.breadcrumbs$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((breadcrumbs) => {
        this.breadcrumbsService.set(breadcrumbs);
      });
    this.initCustomerTypeOptions();
  }

  ngOnInit(): void {
    combineLatest([
      this.agencyId$,
      toObservable(this.currentClients, { injector: this.injector }),
    ])
      .pipe(
        filter(([agencyId, clients]) => !!agencyId && !!clients),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([agencyId, clients]) => {
        this.agencyLink = `${this.createUrl}/${agencyId}`;

        this.usersDataSource.updateAccessIds([
          agencyId!,
          ...clients.map((c) => c._id.toString()),
        ]);
      });

    this.agency$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((agency) => (agency ? this.setAgency(agency) : null));

    this.agencyForm
      .get('customerType')
      ?.valueChanges.pipe(
        withLatestFrom(this.agency$),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([customerType, agency]) => {
        this.setPremiumValues(customerType);

        const isDefaultAgencyUsersAmount =
          agency &&
          agency?.featureAccess?.maxUsersAmount?.value ===
            DEFAULT_USERS_AMOUNT_PER_CUSTOMER_TYPE[
              customerType as kit.CustomerType
            ];

        if (!agency || isDefaultAgencyUsersAmount) {
          this.agencyForm.controls.featureSettings
            .get('maxUsersAmount')
            ?.patchValue({
              value:
                DEFAULT_USERS_AMOUNT_PER_CUSTOMER_TYPE[
                  customerType as kit.CustomerType
                ],
            });
        }

        this.agencyForm.controls.featureSettings
          .get('customerAdmin')
          ?.patchValue({
            value:
              DEFAULT_CUSTOMER_ADMIN_PER_CUSTOMER_TYPE[
                customerType as kit.CustomerType
              ],
          });
      });

    this.agencyId$
      .pipe(
        switchMap((agencyId) =>
          agencyId ? this.clientService.getClients(agencyId) : of([])
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((clients) => {
        this.currentClients.set(clients);

        this.agencyForm.valueChanges
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => {
            this.hasUnsavedFormChanges = true;
          });
        this.agencyForm
          .get('featureSettings')
          ?.get('videoblocks')
          ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => {
            this.hasUnsavedPremiumChanges = true;
          });
        this.agencyForm
          .get('featureSettings')
          ?.get('getty')
          ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => {
            this.hasUnsavedPremiumChanges = true;
          });
        this.agencyForm
          .get('featureSettings')
          ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => {
            this.hasUnsavedPremiumChanges = true;
          });
      });
  }

  setAgency(agency: Agency): void {
    this.setPremiumValues(agency.customerType);
    this.updateAgencyFormFields(agency);

    const freeTrialExpirationDate = agency.freeTrialExpirationDate;
    const hasFreeTrialExpirationDate = !!freeTrialExpirationDate;
    this.agencyForm.controls.freeTrial.setValue(hasFreeTrialExpirationDate);
    if (hasFreeTrialExpirationDate) {
      this.agencyForm.controls.freeTrialExpirationDate.setValue(
        freeTrialExpirationDate
      );
      if (new Date(freeTrialExpirationDate) < new Date() && !agency.active) {
        this.hasExpiredTrial = true;
      }
      if (this.hasExpiredTrial) {
        this.agencyForm.disable();
      }
    }
  }

  initCustomerTypeOptions() {
    if (this.mode() === Modes.Edit) {
      if (!this.customerTypeOptions.includes(CustomerTypes.STANDARD)) {
        this.customerTypeOptions.push(CustomerTypes.STANDARD);
      }
      if (!this.customerTypeOptions.includes(CustomerTypes.STARTER)) {
        this.customerTypeOptions.push(CustomerTypes.STARTER);
      }
    }
  }

  fontChangesHandler(fonts: boolean) {
    this.hasUnsavedFontChanges = fonts;
  }

  loadClients(): void {
    this.agencyId$
      .pipe(
        take(1),
        switchMap((agencyId) => {
          if (!agencyId) {
            return of([]);
          }
          return this.clientService.getClients(agencyId);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((clients) => {
        this.currentClients.set(clients);
      });
  }

  async createAgency(): Promise<void> {
    try {
      this.formLoading = true;
      const agency = await lastValueFrom(
        this.agencyService.createAgency(this.agencyForm.value)
      );
      this.notification.show('success', AgencyNotification.Created);
      this.cache.removeCacheEntry('clientsAgencies');
      void this.router.navigate(['/agencies', agency._id]);
    } catch (error) {
      this.notification.show('error', AgencyNotification.NotCreated);
    }
    this.formLoading = false;
  }

  async updateAgency(): Promise<void> {
    const agencyId = (await firstValueFrom(this.agencyId$))!;
    this.formLoading = true;
    try {
      const agency = { ...this.agencyForm.value, _id: agencyId };
      await lastValueFrom(this.agencyService.updateAgency(agency));

      const updatedClients = await lastValueFrom(this.updateClients(), {
        defaultValue: [],
      });
      this.currentClients.update((clients) =>
        clients.map((client) => {
          const updatedClient = updatedClients.find(
            (updatedClient) => updatedClient._id === client._id
          );
          return updatedClient ?? client;
        })
      );

      const createdClients = await lastValueFrom(
        this.createClientRequests(agencyId),
        { defaultValue: [] }
      );
      if (createdClients.length > 0) {
        this.currentClients.update((clients) => [
          ...clients,
          ...createdClients,
        ]);
      }

      const deletedClientsCount = await lastValueFrom(
        this.deleteClients(this.clientsToDelete)
      );
      const didNotDeleteRequestedClients =
        this.clientsToDelete.length > 0 && deletedClientsCount === 0;

      if (
        createdClients.length ||
        updatedClients.length ||
        deletedClientsCount
      ) {
        this.cache.removeCacheEntry('clientsAgencies');
      }

      this.fonts$ = this.cwsService.listFonts(agencyId);

      this.updateAgencyFormFields(agency);
      this.resetFlags();

      this.notification.show(
        'success',
        `${AgencyNotification.Updated} ${didNotDeleteRequestedClients ? `, ${AgencyNotification.ClientsNotDeleted}` : ''}`
      );
    } catch (error) {
      this.notification.show('error', AgencyNotification.NotUpdated);
      throw error;
    }

    this.loadClients();

    this.formLoading = false;
  }

  private deleteClients(deleteClientIds: string[]): Observable<number> {
    if (!deleteClientIds.length) {
      return of(0);
    }

    const dialogRef = ConfirmDialogComponent.open(this.dialog, {
      action: 'Delete',
      subject: 'clients',
      amount: deleteClientIds.length,
    });

    return dialogRef.afterClosed().pipe(
      filter((deleteClientsConfirmed: boolean) => deleteClientsConfirmed),
      switchMap(() =>
        from(deleteClientIds).pipe(
          switchMap((clientId) => this.clientService.deleteClient(clientId))
        )
      ),
      count()
    );
  }

  private createClientRequests(
    agencyId: string
  ): Observable<Required<IClient>[]> {
    const clientCreateRequests: Observable<Client>[] = this.clientsToCreate.map(
      (client) => this.clientService.createClient(client, agencyId)
    );
    return forkJoin(clientCreateRequests).pipe(
      takeUntilDestroyed(this.destroyRef)
    );
  }

  private updateClients(): Observable<Required<IClient>[]> {
    return forkJoin(
      this.clientsToUpdate.map((client) =>
        this.clientService.updateClient(client._id, client)
      )
    );
  }

  resetFlags(): void {
    this.hasUnsavedClientChanges = false;
    this.hasUnsavedPremiumChanges = false;
    this.hasUnsavedFontChanges = false;
    this.hasUnsavedFormChanges = false;
  }

  async openDeleteDialog(): Promise<void> {
    const dialogRef = ConfirmDialogComponent.open(this.dialog, {
      action: 'Delete',
      subject: 'agency',
    });
    const confirmed = await lastValueFrom(dialogRef.afterClosed());

    if (confirmed) {
      await this.deleteAgency();
    }
  }

  async openRestartTrialDialog(): Promise<void> {
    const dialogRef = ConfirmDialogComponent.open(this.dialog, {
      action: 'Reactivate',
      subject: 'agency',
    });
    const confirmed = await lastValueFrom(dialogRef.afterClosed());

    if (confirmed) {
      await this.restartTrial();
    }
  }

  private async deleteAgency(): Promise<void> {
    const agencyId = (await firstValueFrom(this.agencyId$))!;

    try {
      await lastValueFrom(this.agencyService.deleteAgency(agencyId));
      this.notification.show('success', AgencyNotification.Deleted);
      this.router.navigate(['/agencies']);
    } catch (error) {
      this.notification.show('error', AgencyNotification.NotDeleted);
    }
  }

  private async restartTrial(): Promise<void> {
    const agency = (await firstValueFrom(this.agency$))!;

    try {
      const restartTrialResponse = await lastValueFrom(
        this.cwsService.restartTrial(agency._id)
      );

      this.notification.show('success', AgencyNotification.RestartedTrial);
      if (restartTrialResponse) {
        const updatedAgency = {
          ...agency,
          freeTrialExpirationDate: restartTrialResponse.freeTrialExpirationDate,
        };
        this.agencyForm.enable();
        this.hasExpiredTrial = false;
        this.setAgency(updatedAgency);
        this.resetFlags();
      }
    } catch (error) {
      this.notification.show('error', AgencyNotification.NotRestartedTrial);
    }
  }

  private updateAgencyFormFields(agency: Agency) {
    this.agencyForm.patchValue(agency);
    this.agencyForm.controls.featureSettings.patchValue(agency.featureAccess);
  }

  private freeTrialExpirationDateValidator(
    formGroup: FormGroup<FreeTrialForm>
  ): ValidationErrors | null {
    if (formGroup.value.freeTrial) {
      return Validators.required(formGroup.get('freeTrialExpirationDate')!);
    }

    return null;
  }

  private setPremiumValues(customerType: string) {
    switch (customerType) {
      case CustomerTypes.LITE:
        this.patchPremiumValues('premiumMusic', false, true);
        this.patchPremiumValues('videoblocks', false, true);
        this.patchPremiumValues('getty', false, true);
        this.patchPremiumValues('scriptCreators', true, false);
        this.patchPremiumValues('ruleSets', false, true);
        break;
      case CustomerTypes.PRO:
        this.patchPremiumValues('premiumMusic', true, true);
        this.patchPremiumValues('videoblocks', true, false);
        this.patchPremiumValues('getty', false, true);
        this.patchPremiumValues('scriptCreators', true, true);
        this.patchPremiumValues('ruleSets', false, false);
        break;
      case CustomerTypes.ENTERPRISE:
        this.patchPremiumValues('premiumMusic', true, true);
        this.patchPremiumValues('videoblocks', true, false);
        this.patchPremiumValues('getty', false, true);
        this.patchPremiumValues('scriptCreators', true, true);
        this.patchPremiumValues('ruleSets', true, false);
        break;
      /** @deprecated - starter is deprecated */
      case CustomerTypes.STARTER:
        this.patchPremiumValues('premiumMusic', false, true);
        this.patchPremiumValues('videoblocks', false, true);
        this.patchPremiumValues('getty', false, true);
        this.patchPremiumValues('scriptCreators', true, true);
        this.patchPremiumValues('ruleSets', false, false);
        break;
      /** @deprecated - standard is deprecated */
      case CustomerTypes.STANDARD:
        this.patchPremiumValues('premiumMusic', false, true);
        this.patchPremiumValues('videoblocks', true, false);
        this.patchPremiumValues('getty', false, true);
        this.patchPremiumValues('scriptCreators', true, true);
        this.patchPremiumValues('ruleSets', false, false);
        break;
      default:
        break;
    }
  }

  private patchPremiumValues(
    formField: string,
    enabledFeature: boolean,
    enabledForm: boolean
  ) {
    this.agencyForm.controls.featureSettings
      .get(formField)
      ?.patchValue({ value: enabledFeature });
    if (enabledForm) {
      this.agencyForm.controls.featureSettings.get(formField)?.enable();
    } else {
      this.agencyForm.controls.featureSettings.get(formField)?.disable();
    }
  }
}
