import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormRecord,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { ClientService } from '@core/services/client.service';
import { CwsService } from '@core/services/cws.service';
import { FontUploadService } from '@core/services/font-upload.service';
import { Client } from '@models/client';
import {
  FontItemSettings,
  TVideoVariableThemeFont,
  UpdatedFonts,
} from '@models/font';
import { MATERIAL_MODULES } from '@shared/material-design/material-modules';
import { AgencyNotification } from '@shared/notification/agency-messages';
import { ClientNotification } from '@shared/notification/client-messages';
import { NotificationService } from '@shared/notification/notification.service';
import { ConfirmDialogComponent } from '@views/partials/confirm-dialog/confirm-dialog.component';
import { FileDropComponent, FileDropEntry } from '@views/partials/file-drop';
import { Observable, Subject, concat, lastValueFrom, of } from 'rxjs';
import { catchError, finalize, map, startWith, tap } from 'rxjs/operators';

import { VideoFont } from '@storykit/typings/src/cws';

interface FontItemSettingsFormControl {
  _id: FormControl<string>;
  fontStyle: FormControl<string>;
  size: FormControl<{ value: number; disabled: boolean }>;
  leading: FormControl<{ value: number; disabled: boolean }>;
  tracking: FormControl<{ value: number; disabled: boolean }>;
}

@Component({
  selector: 'app-font-calc-list',
  templateUrl: './font-calc-list.component.html',
  styleUrls: ['./font-calc-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    MATERIAL_MODULES,
    AsyncPipe,
    MatTooltip,
    FileDropComponent,
  ],
})
export class FontCalcListComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(MatTable)
  table!: MatTable<any>;

  @ViewChild(PageEvent, { static: false }) paginator!: PageEvent;

  @ViewChild(FileDropComponent, { static: false })
  fileDropComponent!: FileDropComponent;

  @Input()
  fonts: VideoFont[] = [];
  @Input() agencyId = '';
  @Input() clients: Client[] = [];

  @Output() hasUnsavedFontChanges = new EventEmitter<boolean>();

  public isUploading = false;
  public loading = true;
  public formLoading = false;
  public disableUpload = true;
  public masterFont = 'large';
  public pageSizeSelected = 5;

  clientsArr: Client[] = [];
  clientsFontControls: Record<string, FormControl<kit.IClient[]>> = {}; // list of clients which have access to particular font
  fontClients: Record<string, Client[]> = {};

  public expandedElement: FontItemSettings | null | undefined;
  public hasUnsavedFontValuesChanges: boolean[] = [];
  public displayedFonts = new MatTableDataSource<VideoFont>();
  public dataSource: VideoFont[] = [];
  public files: File[] = [];
  public headerColumns: string[] = [
    'font',
    'weight',
    'size',
    'leading',
    'tracking',
    'capHeight',
    'xHeight',
    'uploaded-by',
    'lock',
    'download',
    'trashcan',
    'action',
  ];
  public notDisplayedColumns: string[] = [
    'lock',
    'download',
    'trashcan',
    'action',
  ];
  public fontFormControls!: Observable<AbstractControl[]>;
  public disabledInput = new Set<number>(); // unique index
  public forms: FormArray[] = [];
  public form = new FormRecord<
    FormArray<FormGroup<FontItemSettingsFormControl>>
  >({});

  private destroy$ = new Subject<void>();
  private fontStylesFilter: string[] = [
    'huge',
    'hugeNumber',
    'xlarge',
    'xlargeAlternate',
    'large',
    'largeAlternate',
    'medium',
    'mediumAlternate',
    'small',
    'smallAlternate',
    'tiny',
  ];

  constructor(
    private cwsService: CwsService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private clientService: ClientService,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef,
    private fus: FontUploadService
  ) {}

  getFontFormControls(i: number) {
    return (this.form.get(`fontSettings${i}`) as FormArray).controls;
  }

  ngOnInit(): void {
    this.loading = true;
    this.initSources();
  }

  ngOnChanges() {
    this.initSources();
  }

  initSources() {
    this.dataSource = this.fonts ? this.fonts : [];
    this.clientsArr = this.clients ? this.clients : [];
    this.hasUnsavedFontValuesChanges = Array(this.dataSource.length).fill(
      false
    );
    this.pageInit({
      pageIndex: 0,
      pageSize: 5,
      length: this.displayedFonts.data.length,
    });
  }

  pageInit(event: PageEvent) {
    this.form = new FormRecord<
      FormArray<FormGroup<FontItemSettingsFormControl>>
    >({});
    const dataSource = [...this.dataSource];
    this.pageSizeSelected = event.pageSize;

    this.displayedFonts = new MatTableDataSource(
      dataSource.splice(event.pageIndex * event.pageSize, event.pageSize)
    );

    this.displayedFonts.data.forEach((data, i) => {
      this.initRow(data, i);
    });

    this.loading = false;
  }

  initRow(font: VideoFont, index: number) {
    this.fontFormControls = this.form.valueChanges.pipe(
      startWith(undefined),
      map(() => this.getFontFormControls(index))
    );

    // @ts-expect-error unknown error
    this.form.setControl(`fontSettings${index}`, this.setFontFormValues(font));
    this.lockInputs(index);
    this.initFontAccess(font._id);
    this.lockFontAccessSelector(font._id);
  }

  async updateFonts() {
    await this.mapFormValues();
    this.hasUnsavedFontValuesChanges = Array(this.dataSource.length).fill(
      false
    );
    this.hasUnsavedFontChanges.emit(false);
  }

  async updateClients() {
    const clientsToUpdate = this.getClientsUpdates();
    await this.updateClientsRestrictedFonts(clientsToUpdate);
  }

  lockInputs(index: number) {
    const controls = this.getFontFormControls(index);
    this.disabledInput.add(index);
    controls.forEach((input) => {
      input.disable();
    });
  }

  unlockInputs(index: number) {
    const controls = this.getFontFormControls(index);
    const hasIndex = this.disabledInput.has(index);
    if (hasIndex) {
      this.disabledInput.delete(index);
    }
    controls.forEach((input) => {
      input.enable();
    });
  }

  lockFontAccessSelector(fontId: string): void {
    const fontControl = this.clientsFontControls[fontId];
    if (!fontControl) {
      return;
    }
    fontControl.disable();
  }

  unlockFontAccessSelector(fontId: string): void {
    const fontControl = this.clientsFontControls[fontId];
    if (!fontControl) {
      return;
    }
    fontControl.enable();
  }

  lockRow(index: number, fontId: string): void {
    this.lockInputs(index);
    this.lockFontAccessSelector(fontId);
  }

  unlockRow(index: number, fontId: string): void {
    this.unlockInputs(index);
    this.unlockFontAccessSelector(fontId);
  }

  async openDeleteDialog(index: number): Promise<void> {
    const dialogRef = ConfirmDialogComponent.open(this.dialog, {
      action: 'Delete',
      subject: 'font',
    });
    const confirmed = await lastValueFrom(dialogRef.afterClosed());

    if (confirmed) {
      this.formLoading = true;
      const fontToBeDeleteId = this.displayedFonts.data[index]._id;

      if (fontToBeDeleteId) {
        await lastValueFrom(
          this.cwsService.deleteFont(this.agencyId, fontToBeDeleteId)
        )
          .then(() => {
            const datasourceIndex = this.dataSource.findIndex(
              (font) => (font._id = fontToBeDeleteId)
            );
            this.displayedFonts.data.splice(index, 1);
            this.dataSource.splice(datasourceIndex, 1);

            this.formLoading = false;
            this.notificationService.show(
              'success',
              AgencyNotification.FontDeleted
            );
          })
          .catch((err) => {
            const errorMsg = `${
              AgencyNotification.FontNotDeleted
            } due to Error: ${err?.error?.message || err?.message}`;
            this.notificationService.show('error', errorMsg);
          });
      }
    }

    this.refreshDataSource();
  }

  refreshDataSource() {
    this.table.renderRows();
  }

  downloadFont(index: number) {
    const fontToBeDownloaded = this.displayedFonts.data[index];

    lastValueFrom(
      this.cwsService.getSignedFontUrl(this.agencyId, fontToBeDownloaded._id)
    ).then((r) => {
      const url = r.url;
      const link = document.createElement('a');
      link.href = url;
      link.click();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // map font list with allowed clients to clients with restricted fonts
  getClientRestrictedFonts(clientId: string): string[] {
    return Object.keys(this.clientsFontControls).reduce(
      (acc: string[], fontKey: string) => {
        const allowedClientIds = this.clientsFontControls[fontKey].value.map(
          (c: any) => c._id
        );
        const isFontRestricted = !allowedClientIds.includes(clientId);
        if (isFontRestricted) {
          acc.push(fontKey);
        }
        return acc;
      },
      []
    );
  }

  getClientsUpdates(): Client[] {
    return this.clientsArr.map((client) => {
      const restrictedFonts = this.getClientRestrictedFonts(client._id);
      return {
        ...client,
        restrictedFonts,
      };
    });
  }

  getAllowedClients(fontId: string): Client[] {
    return this.clientsArr.filter((client) => {
      const { restrictedFonts = [] } = client;
      const isFontAllowed = !restrictedFonts.includes(fontId);
      return isFontAllowed;
    });
  }

  objectComparisonFunction(option: any, value: any): boolean {
    return option._id === value?._id;
  }

  public uploadFont() {
    this.isUploading = true;
    this.cdr.detectChanges();

    // TODO: upload the fonts synchronously to avoid each asynchronous agency update to overwrite each other.
    //  In the future we should probably add support for bulk adding fonts to agencies.
    concat(
      ...Array.from(this.files).map((file) =>
        this.fus.uploadToCws(file, this.agencyId).pipe(
          tap((font) => {
            this.dataSource.push(font);
            if (this.displayedFonts.data.length < this.pageSizeSelected) {
              this.displayedFonts.data.push(font);
            }
          }),
          catchError(() => {
            this.notificationService.show(
              'error',
              AgencyNotification.FontNotUploaded
            );

            return of({ success: false, file });
          })
        )
      )
    )
      .pipe(
        finalize(() => {
          this.displayedFonts.data.forEach((data, i) => this.initRow(data, i));
          this.displayedFonts.data = [...this.displayedFonts.data];
          this.isUploading = false;
          this.files = [];
          this.disableUpload = true;
          this.fileDropComponent.resetAfterUpload();
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  public dropped(files: FileDropEntry[]) {
    if (!files.length) {
      this.resetFilesToUpload();
      this.hasUnsavedFontChanges.emit(false);
    } else {
      this.addFilesToUpload(files);
      this.hasUnsavedFontChanges.emit(true);
    }
  }

  public onFontValuesChange(index: number) {
    this.hasUnsavedFontValuesChanges[index] = true;
    this.form?.markAsDirty();
    this.hasUnsavedFontChanges.emit(this.form?.dirty);
  }

  private addFilesToUpload(files: FileDropEntry[]) {
    this.files = [];
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        this.disableUpload = false;
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.files.push(file);
        });
      }
    }
  }

  private resetFilesToUpload() {
    this.disableUpload = true;
    this.files = [];
  }

  private async updateClientsRestrictedFonts(
    updateData: Client[]
  ): Promise<void> {
    try {
      const clientsToUpdate = updateData.map((client) => {
        const { restrictedFonts = [] } = client;
        return lastValueFrom(
          this.clientService.updateClient(client._id, {
            restrictedFonts, // update only one property
          })
        );
      });
      await Promise.all(clientsToUpdate);
      this.notificationService.show(
        'success',
        ClientNotification.MultipleUpdated
      );
    } catch (error) {
      this.notificationService.show(
        'error',
        ClientNotification.MultipleNotUpdated
      );
    }
  }

  private initFontAccess(fontId: string): void {
    this.fontClients[fontId] = [...this.clientsArr]; // add list of clients for each font

    this.clientsFontControls[fontId] = new FormControl<kit.IClient[]>([], {
      nonNullable: true,
    });
    const allowedClients = this.getAllowedClients(fontId);
    this.clientsFontControls[fontId].setValue(allowedClients); // set initial value for selected options
  }

  /***
   * Filter the font object using fontStyles provided
   * and map it into a formArray
   */

  private getFormValues(fontObj: VideoFont) {
    const fontStyleSettings: FontItemSettings[] = [];

    Object.keys(fontObj)
      .filter((key) => this.fontStylesFilter.includes(key))
      .reduce((obj, key) => {
        const settings = fontObj[key as TVideoVariableThemeFont];
        const formObj = {
          _id: fontObj._id,
          fontStyle: key,
          ...settings,
        };

        return fontStyleSettings.push(formObj);
      }, {});

    return fontStyleSettings;
  }

  private setFontFormValues(fontObj: VideoFont) {
    const formValues = this.getFormValues(fontObj);
    const formGroups = formValues.map((value) => this.createFormGroup(value));

    return new FormArray(formGroups);
  }

  private createFormGroup(fontGroup: FontItemSettings) {
    return this.formBuilder.nonNullable.group({
      _id: fontGroup._id,
      fontStyle: fontGroup.fontStyle,
      size: [{ value: fontGroup.size, disabled: true }, Validators.required],
      leading: [
        { value: fontGroup.leading, disabled: true },
        Validators.required,
      ],
      tracking: [
        { value: fontGroup.tracking, disabled: true },
        Validators.required,
      ],
      capHeight: [
        { value: fontGroup.capHeight, disabled: true },
        Validators.required,
      ],
      xHeight: [
        { value: fontGroup.xHeight, disabled: true },
        Validators.required,
      ],
    });
  }

  private async mapFormValues() {
    this.formLoading = true;

    const formControls = this.form.controls;
    const updatedFonts: UpdatedFonts[] = [];
    Object.values(formControls).forEach((control: any) => {
      if (control.enabled) {
        const formValues = control.value as any[];
        const id = formValues[0]._id;

        const reducedFormValues = formValues.reduce((acc, item) => {
          const objectKey = item.fontStyle as TVideoVariableThemeFont;

          acc[objectKey] = {
            size: item.size,
            leading: item.leading,
            tracking: item.tracking,
            capHeight: item.capHeight,
            xHeight: item.xHeight,
          };
          return acc;
        }, {});

        updatedFonts.push({
          _id: id,
          ...reducedFormValues,
        });
      }
    });

    await lastValueFrom(this.cwsService.updateFonts(updatedFonts))
      .then(() => {
        this.notificationService.show(
          'success',
          AgencyNotification.FontUpdated
        );
      })
      .catch(() =>
        this.notificationService.show(
          'error',
          AgencyNotification.FontNotUpdated
        )
      );

    this.formLoading = false;
  }
}
