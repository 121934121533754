import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { CwsService } from '@core/services/cws.service';
import { MATERIAL_MODULES } from '@shared/material-design/material-modules';
import { CohortInfoComponent } from '@views/feature-flags/cohort-info/cohort-info.component';
import { UserAutocompleteSelectboxComponent } from '@views/feature-flags/user-autocomplete-selectbox/user-autocomplete-selectbox.component';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-feature-flags-list',
  templateUrl: './feature-flags-list.component.html',
  styleUrl: './feature-flags-list.component.scss',
  standalone: true,
  imports: [
    MATERIAL_MODULES,
    RouterLink,
    CommonModule,
    CohortInfoComponent,
    FormsModule,
    ReactiveFormsModule,
    UserAutocompleteSelectboxComponent,
  ],
})
export class FeatureFlagsListComponent {
  checkForm = this.formBuilder.group({
    id: ['', Validators.required],
  });

  featureFlags$ = this.cwsService.getFeatureFlags();
  checks: [boolean, string, string][] | undefined;

  constructor(
    private cwsService: CwsService,
    private route: Router,
    private formBuilder: FormBuilder
  ) {}

  async createFeatureFlag(key: string) {
    const featureFlag = await lastValueFrom(
      this.cwsService.createFeatureFlag(key)
    );
    void this.route.navigate(['feature-flags', featureFlag._id]);
  }

  async checkUser(userId: string) {
    this.checks = await lastValueFrom(
      this.cwsService.getAllFeatureFlagReasons(userId)
    );
  }
}
