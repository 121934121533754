import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from '@core/services/breadcrumbs.service';
import { SlideDefinitionLayoutService } from '@core/services/slide-definition-layout.service';
import { SlideDefinitionService } from '@core/services/slide-definition.service';
import { Modes } from '@models/modes';
import { SlideDefinition, SlideDefinitionType } from '@models/slide-definition';
import { NotificationService } from '@shared/notification/notification.service';
import { SlideDefinitionNotification } from '@shared/notification/slide-definition-messages';
import { ConfirmDialogComponent } from '@views/partials/confirm-dialog/confirm-dialog.component';
import { Subject, combineLatest, lastValueFrom, of } from 'rxjs';
import {
  first,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

import { AccessCategory } from '@storykit/constants';
import { VideoSlideDefinitionGroupDTO } from '@storykit/typings/src/cws';

@Component({
  selector: 'app-slide-definition',
  templateUrl: './slide-definition.component.html',
  styleUrls: [
    './slide-definition.component.scss',
    '../../../../styles/settings-form.scss',
  ],
  providers: [SlideDefinitionLayoutService],
})
export class SlideDefinitionComponent implements OnInit, OnDestroy {
  accessCategories = AccessCategory;

  modes = Modes;
  slideDefinitionTypes: SlideDefinitionType[] = [];
  slideDefinitionGroups: VideoSlideDefinitionGroupDTO[] = [];
  slideDefinitionLayouts$ =
    this.slideDefinitionLayoutService.listSlideDefinitionLayouts();

  hasUnsavedFormChanges = false;

  formLoading = false;

  mode$ = this.route.data.pipe(map(({ mode }) => mode as Modes));

  slideDefinitionId$ = this.route.params.pipe(
    map(({ slideDefinitionId }) =>
      slideDefinitionId ? String(slideDefinitionId) : undefined
    )
  );

  originalSlideDefinition: SlideDefinition | undefined;
  slideDefinition$ = this.slideDefinitionId$.pipe(
    switchMap((slideDefinitionId) =>
      slideDefinitionId
        ? this.slideDefinitionService.getSlideDefinition(slideDefinitionId)
        : of(undefined)
    ),
    shareReplay(1)
  );

  loading$ = this.slideDefinition$.pipe(
    map(() => false),
    startWith(true)
  );

  slideDefinitionForm = this.formBuilder.group(
    {
      displayName: ['', Validators.required],
      accessCategory: [AccessCategory.BASIC, Validators.required],
      infoLink: [''],
      previewVideo: [''],
      garbo: this.formBuilder.group({
        preview: this.formBuilder.group({
          atTime: [0.5, Validators.required],
          clamp: this.formBuilder.group({
            start: [undefined],
            end: [undefined],
          }),
        }),
      }),
      variables: ['{}', Validators.required],
      availableForClients: this.formBuilder.array([]),
      availableForAgencies: this.formBuilder.array([]),
      toggleDefinitionTypeSelect: ['New', Validators.required],
      definitionTypeName: [''],
      definitionType: [''],
      definitionGroup: ['', Validators.required],
      timings: this.formBuilder.group({
        editable: [false, Validators.required],
        duration: [5000, Validators.required],
        inheritsFrom: ['duration'],
      }),
      layout: [null],
    },
    {
      validators: [(_: FormGroup) => null],
    }
  );

  private destroy$ = new Subject<void>();

  private breadcrumbs$ = combineLatest([
    this.slideDefinition$,
    this.mode$,
  ]).pipe(
    map(([slideDefinition, mode]) => {
      const name =
        mode === Modes.Add
          ? 'New Slide Definition'
          : slideDefinition?.displayName || '';
      return [
        { label: 'Slide Definitions', link: '/slide-definitions' },
        { label: name },
      ];
    }),
    startWith([
      { label: 'Slide Definitions', link: '/slide-definitions' },
      { label: '' },
    ])
  );

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private slideDefinitionService: SlideDefinitionService,
    private slideDefinitionLayoutService: SlideDefinitionLayoutService,
    private breadcrumbsService: BreadcrumbsService,
    private formBuilder: FormBuilder,
    private notification: NotificationService,
    private dialog: MatDialog
  ) {
    this.breadcrumbs$
      .pipe(takeUntil(this.destroy$))
      .subscribe((breadcrumbs) => {
        this.breadcrumbsService.set(breadcrumbs);
      });
  }

  ngOnInit(): void {
    this.slideDefinition$.subscribe((slideDefinition) => {
      if (slideDefinition) {
        this.originalSlideDefinition = slideDefinition;
        this.updateSlideDefinitionFormFields(slideDefinition);
      }
    });
    this.slideDefinitionService.getSlideDefinitionTypes().subscribe((types) => {
      this.slideDefinitionTypes = types;
    });
    this.slideDefinitionService
      .getSlideDefinitionGroups()
      .subscribe(({ definitionGroups }) => {
        this.slideDefinitionGroups = definitionGroups;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  formatSliderLabel(value: number): string {
    if (!value) {
      return '0';
    }

    return `${value * 100}%`;
  }

  async slideDefinitionPayload(): Promise<SlideDefinition> {
    if (this.slideDefinitionForm.value.toggleDefinitionTypeSelect === 'New') {
      const definitionType = await lastValueFrom(
        this.slideDefinitionService.createSlideDefinitionType(
          this.slideDefinitionForm.value.definitionTypeName
        )
      );
      this.slideDefinitionTypes.push(definitionType);
      this.slideDefinitionForm.patchValue({
        toggleDefinitionTypeSelect: 'Keep',
        definitionTypeName: '',
        definitionType: definitionType._id,
      });
    }
    const { _id, ...original } = this.originalSlideDefinition ?? {};

    return {
      ...original,
      ...this.slideDefinitionForm.value,
      garbo: {
        preview: this.slideDefinitionForm.value.garbo.preview,
      },
      variables: JSON.parse(this.slideDefinitionForm.get('variables')?.value),
      layout: this.slideDefinitionForm.get('layout')?.value ?? null,
    };
  }

  async createSlideDefinition(): Promise<void> {
    try {
      this.formLoading = true;
      const slideDefinition = await lastValueFrom(
        this.slideDefinitionService.createSlideDefinition(
          await this.slideDefinitionPayload()
        )
      );

      this.notification.show('success', SlideDefinitionNotification.Created);
      this.router.navigate(['/slide-definitions', slideDefinition._id]);
    } catch (error) {
      this.notification.show('error', SlideDefinitionNotification.NotCreated);
    }
    this.formLoading = false;
  }

  async updateSlideDefinition(): Promise<void> {
    const slideDefinitionId = (await lastValueFrom(
      this.slideDefinitionId$.pipe(first())
    ))!;
    this.formLoading = true;
    try {
      const slideDefinition = {
        ...(await this.slideDefinitionPayload()),
        _id: slideDefinitionId,
      };
      await lastValueFrom(
        this.slideDefinitionService.updateSlideDefinition(slideDefinition)
      );

      this.updateSlideDefinitionFormFields(slideDefinition);
      this.hasUnsavedFormChanges = false;

      this.notification.show('success', SlideDefinitionNotification.Updated);
    } catch (error) {
      this.notification.show('error', SlideDefinitionNotification.NotUpdated);
    }

    this.formLoading = false;
  }

  async openDeleteDialog(): Promise<void> {
    const dialogRef = ConfirmDialogComponent.open(this.dialog, {
      action: 'Delete',
      subject: 'slide definition',
    });
    const confirmed = await lastValueFrom(dialogRef.afterClosed());

    if (confirmed) {
      await this.deleteSlideDefinition();
    }
  }

  private async deleteSlideDefinition(): Promise<void> {
    const slideDefinitionId = (await lastValueFrom(
      this.slideDefinitionId$.pipe(first())
    ))!;

    try {
      await lastValueFrom(
        this.slideDefinitionService.deleteSlideDefinition(slideDefinitionId)
      );
      this.notification.show('success', SlideDefinitionNotification.Deleted);
      this.router.navigate(['/slide-definitions']);
    } catch (error) {
      this.notification.show('error', SlideDefinitionNotification.NotDeleted);
    }
  }

  private updateSlideDefinitionFormFields({
    variables,
    availableForClients,
    availableForAgencies,
    ...slideDefinition
  }: SlideDefinition) {
    this.slideDefinitionForm.patchValue(slideDefinition);
    if (slideDefinition.definitionType) {
      this.slideDefinitionForm.patchValue({
        toggleDefinitionTypeSelect: 'Keep',
      });
    }
    const availableForClientsControl = this.slideDefinitionForm.get(
      'availableForClients'
    ) as FormArray;
    availableForClientsControl.clear();
    availableForClients?.forEach((clientId) => {
      availableForClientsControl.push(this.formBuilder.control(clientId));
    });
    const availableForAgenciesControl = this.slideDefinitionForm.get(
      'availableForAgencies'
    ) as FormArray;
    availableForAgenciesControl.clear();
    availableForAgencies?.forEach((agencyId) => {
      availableForAgenciesControl.push(this.formBuilder.control(agencyId));
    });
    this.slideDefinitionForm
      .get('variables')
      ?.setValue(JSON.stringify(variables, null, 4));
  }
}
