<sk-file-icon [file]="file" />
<div class="file-info-container">
  <div class="file-info">
    <span class="file-name">{{ file.name }}</span>
  </div>
  <div class="file-buttons">
    <button class="remove-btn -icon -small" (click)="handleRemoveFile(file)">
      🗑️
    </button>
    <ng-content />
  </div>
</div>
