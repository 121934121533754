<div class="section">
  <div [formGroup]="form">
    <h3>Auth0 ID</h3>
    <mat-form-field>
      <input
        #in
        type="text"
        matInput
        formControlName="auth0UserId"
        placeholder="Auth0 ID"
        autocomplete="off"
      />
    </mat-form-field>
  </div>
  <h3>Password reset</h3>
  @if (userId$ | async) {
    <button
      (click)="resetPassword.emit()"
      mat-flat-button
      color="primary"
      [skElementId]="elementId.Admin.UserButtonGetPasswordResetLink"
    >
      Get password-reset link
    </button>
  }
  @if (resetLink$) {
    <div class="reset-password-container">
      <app-input-to-clipboard
        [label]="'Copy password-reset link'"
        [content]="resetLink$ | async"
        [notificationMsg]="'Reset link was copied'"
      />
    </div>
  }

  <h3>IP Block</h3>
  <div class="button-container ip-block">
    @if ((ipBlock$ | async) === false || (ipBlock$ | async) === null) {
      <button
        mat-flat-button
        [disabled]="formLoading || form.invalid"
        color="primary"
        (click)="checkIpBlock.emit()"
        [skElementId]="elementId.Admin.UserButtonCheckIPBlock"
      >
        {{
          (ipBlock$ | async) === false ? 'User not blocked' : 'Check IP block'
        }}
      </button>
    }
    @if ((ipBlock$ | async) === true) {
      <button
        mat-stroked-button
        [disabled]="formLoading || form.invalid"
        color="warn"
        (click)="removeIpBlock.emit()"
        [skElementId]="elementId.Admin.UserButtonRemoveIPBlock"
      >
        Unblock user
      </button>
    }
  </div>

  <h3>Danger zone</h3>
  <div class="button-container">
    <button
      mat-flat-button
      [disabled]="formLoading || form.invalid"
      color="warn"
      (click)="delete.emit()"
      [skElementId]="elementId.Admin.UserButtonDelete"
    >
      Delete User
    </button>
    @if (flaggedForDeletion) {
      <button
        mat-stroked-button
        [disabled]="formLoading"
        color="warn"
        (click)="removeDeletedStatus.emit()"
        [skElementId]="elementId.Admin.UserButtonRemoveDeletionFlag"
      >
        Remove Deletion Flag
      </button>
    }
    <button
      mat-stroked-button
      [disabled]="formLoading"
      color="warn"
      (click)="block.emit()"
      [skElementId]="elementId.Admin.UserButtonBlock"
    >
      {{ blocked ? 'Unblock User' : 'Block User' }}
    </button>
  </div>
</div>
