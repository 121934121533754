/* eslint-disable @typescript-eslint/prefer-for-of */
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import JSZip from 'jszip';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { first, map } from 'rxjs/operators';

import {
  FileSystemDirectoryEntry,
  FileSystemEntry,
  FileSystemFileEntry,
} from './dom.types';
import { FileDropEntry } from './file-drop-entry';
import { FileDropModalComponent } from './file-drop-modal/file-drop-modal.component';
import { FileDropContentTemplateDirective } from './file-drop-template.directive';
import { FileListItemComponent } from './file-list-item/file-list-item.component';
import { FileListTemplateDirective } from './file-list-template.directive';

@Component({
  selector: 'sk-file-drop',
  standalone: true,
  imports: [
    NgClass,
    MatProgressSpinnerModule,
    FileListItemComponent,
    AsyncPipe,
    NgTemplateOutlet,
  ],
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss'],
})
export class FileDropComponent implements OnDestroy {
  @Input()
  public accept = '*';

  @Input()
  public directory = false;

  @Input()
  public multiple = true;

  @Input()
  public dropZoneLabel = '';

  @Input()
  public dropZoneClassName = 'file-drop__drop-zone';

  @Input()
  public useDragEnter = false;

  @Input()
  public contentClassName = 'file-drop__content';

  @Input()
  public showBrowseBtn = false;

  @Input()
  public browseBtnClassName =
    'button -small margin-left-tiny file-drop__browse-btn';

  @Input()
  public browseBtnLabel = 'Browse files';

  @Input()
  public useZip = true;

  @Input()
  public optionalZip = false;

  @Input()
  public isLoading!: boolean;

  @Output()
  public fileDrop = new EventEmitter<FileDropEntry[]>();

  @Output()
  public fileOver = new EventEmitter<any>();

  @Output()
  public fileLeave = new EventEmitter<any>();

  // custom templates
  @ContentChild(FileDropContentTemplateDirective, { read: TemplateRef })
  contentTemplate!: TemplateRef<any>;

  @ContentChild(FileListTemplateDirective, { read: TemplateRef })
  fileListTemplate!: TemplateRef<any>;

  @ViewChild('fileSelector', { static: true })
  public fileSelector!: ElementRef;

  public isDraggingOverDropZone = false;

  fileEntries$: Observable<FileSystemFileEntry[]>;
  private fileEntriesSubject$ = new BehaviorSubject<FileDropEntry[]>([]);

  private globalDraggingInProgress = false;
  private readonly globalDragStartListener: () => void;
  private readonly globalDragEndListener: () => void;

  // queue
  private queue: FileDropEntry[] = [];
  private numOfActiveReadEntries = 0;
  private excludeAutoAdd = ['application/zip'];

  private helperFormEl: HTMLFormElement | null = null;
  private fileInputPlaceholderEl: HTMLDivElement | null = null;

  private dropEventTimerSubscription: Subscription | null = null;

  private _disabled = false;
  // Zip
  private filesToBeZippedQueue: File[] = [];
  private zipFile: JSZip = new JSZip();
  private displayingModal = false;

  constructor(
    private zone: NgZone,
    private renderer: Renderer2,
    private dialog: MatDialog
  ) {
    this.fileEntries$ = this.fileEntriesSubject$
      .asObservable()
      .pipe(
        map((fileEntries) =>
          fileEntries
            .filter((e) => e.fileEntry.isFile)
            .map((e) => e.fileEntry as FileSystemFileEntry)
        )
      );

    this.globalDragStartListener = this.renderer.listen(
      'document',
      'dragstart',
      (_event: Event) => {
        this.globalDraggingInProgress = true;
      }
    );
    this.globalDragEndListener = this.renderer.listen(
      'document',
      'dragend',
      (_event: Event) => {
        this.globalDraggingInProgress = false;
      }
    );
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  public set disabled(value: boolean) {
    this._disabled = value != null && `${value}` !== 'false';
  }

  public ngOnDestroy(): void {
    if (this.dropEventTimerSubscription) {
      this.dropEventTimerSubscription.unsubscribe();
      this.dropEventTimerSubscription = null;
    }
    this.globalDragStartListener();
    this.globalDragEndListener();
    this.queue = [];
    this.filesToBeZippedQueue = [];
    this.helperFormEl = null;
    this.fileInputPlaceholderEl = null;
  }

  public onDragOver(event: DragEvent): void {
    if (this.useDragEnter) {
      this.preventAndStop(event);
      if (event.dataTransfer) {
        event.dataTransfer.dropEffect = 'copy';
      }
    } else if (
      !this.isDropzoneDisabled() &&
      !this.useDragEnter &&
      event.dataTransfer
    ) {
      if (!this.isDraggingOverDropZone) {
        this.isDraggingOverDropZone = true;
        this.fileOver.emit(event);
      }
      this.preventAndStop(event);
      event.dataTransfer.dropEffect = 'copy';
    }
  }

  public onDragEnter(event: Event): void {
    if (!this.isDropzoneDisabled() && this.useDragEnter) {
      if (!this.isDraggingOverDropZone) {
        this.isDraggingOverDropZone = true;
        this.fileOver.emit(event);
      }
      this.preventAndStop(event);
    }
  }

  public onDragLeave(event: Event): void {
    if (!this.isDropzoneDisabled()) {
      if (this.isDraggingOverDropZone) {
        this.isDraggingOverDropZone = false;
        this.fileLeave.emit(event);
      }
      this.preventAndStop(event);
    }
  }

  public dropFiles(event: DragEvent): void {
    if (!this.isDropzoneDisabled()) {
      this.isDraggingOverDropZone = false;
      if (event.dataTransfer) {
        let items: FileList | DataTransferItemList;
        if (event.dataTransfer.items) {
          items = event.dataTransfer.items;
        } else {
          items = event.dataTransfer.files;
        }
        this.preventAndStop(event);
        this.checkFiles(items);
      }
    }
  }

  public openFileSelector = (_event?: MouseEvent): void => {
    if (this.fileSelector?.nativeElement) {
      (this.fileSelector.nativeElement as HTMLInputElement).click();
    }
  };

  /**
   * Processes the change event of the file input and adds the given files.
   *
   * @param Event event
   */
  public dropFileChange(event: Event): void {
    if (!this.isDropzoneDisabled()) {
      if (event.target) {
        const items = (event.target as HTMLInputElement).files || ([] as any);
        this.checkFiles(items);
        this.resetFileInput();
      }
    }
  }

  onRemoveFile(file: FileSystemFileEntry): void {
    const fileEntries = this.fileEntriesSubject$.value.filter(
      (fileEntry) => fileEntry.fileEntry !== file
    );
    this.fileEntriesSubject$.next(fileEntries);
    this.fileDrop.emit(fileEntries);
  }

  handleUploadIconClick(): void {
    if (this.showBrowseBtn || this.contentTemplate) {
      return;
    }
    this.openFileSelector();
  }

  /*
   ** Reset fileEntries and emits empty fileEntry[] for use in parent
   *  component when upload has finished
   */

  resetAfterUpload() {
    this.fileEntriesSubject$.next([]);
    this.fileDrop.emit([]);
  }

  private checkFiles(items: FileList | DataTransferItemList): void {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      let entry: FileSystemEntry | null = null;
      const zipFile: File | null = this.canGetAsEntry(item)
        ? item.getAsFile()
        : item;

      if (this.canGetAsEntry(item)) {
        entry = item.webkitGetAsEntry();
      }

      if (zipFile && this.useZip && item.type === 'application/zip') {
        this.filesToBeZippedQueue.push(zipFile);
      }

      if (!entry) {
        if (item) {
          const toProcess: FileDropEntry =
            this.createFileDropEntryFromDTIorFile(item);
          this.addToQueue(toProcess);
        }
      } else {
        if (entry.isFile && !this.excludeAutoAdd.includes(item.type)) {
          const toProcess: FileDropEntry = new FileDropEntry(entry.name, entry);
          this.addToQueue(toProcess);
        } else if (entry.isDirectory) {
          this.traverseFileTree(entry, entry.name);
        }
      }
    }
    if (this.dropEventTimerSubscription) {
      this.dropEventTimerSubscription.unsubscribe();
    }
    this.dropEventTimerSubscription = timer(200, 200).subscribe(async () => {
      if (this.filesToBeZippedQueue.length > 0 && !this.displayingModal) {
        if (this.optionalZip) {
          this.unzipFileQueueModal();
        } else {
          await this.unzipFiles();
        }
      }
      if (
        this.queue.length > 0 &&
        this.numOfActiveReadEntries === 0 &&
        !this.displayingModal
      ) {
        const files = this.queue;
        this.queue = [];
        this.zipReset();

        this.fileEntriesSubject$.next(files);
        this.fileDrop.emit(files);
      }
    });
  }

  private isAccepted(file: File, accept: string): boolean {
    if (file.name.startsWith('.')) {
      return false;
    }
    if (accept === '*') {
      return true;
    }

    const acceptFiletypes = accept
      .split(',')
      .map((it) => it.toLowerCase().trim());
    const filetype = file.type.toLowerCase();
    const filename = file.name.toLowerCase();

    const matchedFileType = acceptFiletypes.find((acceptFiletype) => {
      // check for wildcard mimetype (e.g. image/*)
      if (acceptFiletype.endsWith('/*')) {
        return filetype.split('/')[0] === acceptFiletype.split('/')[0];
      }

      // check for file extension (e.g. .csv)
      if (acceptFiletype.startsWith('.')) {
        return filename.endsWith(acceptFiletype);
      }

      // check for exact mimetype match (e.g. image/jpeg)
      return acceptFiletype === filetype;
    });

    return !!matchedFileType;
  }

  private async traverseFileTree(
    item: FileSystemEntry,
    path: string
  ): Promise<void> {
    if (item.isFile) {
      const zipFile = await this.getFile(item as FileSystemFileEntry);
      if (this.useZip && zipFile && zipFile.type === 'application/zip') {
        this.filesToBeZippedQueue.push(zipFile);
      } else {
        const toProcess: FileDropEntry = new FileDropEntry(path, item);
        this.addToQueue(toProcess);
      }
    } else {
      path = path + '/';
      const dirReader = (item as FileSystemDirectoryEntry).createReader();
      let entries: FileSystemEntry[] = [];

      const readEntries = () => {
        this.numOfActiveReadEntries++;
        dirReader.readEntries((result) => {
          if (!result.length) {
            // add empty folders
            if (entries.length === 0) {
              const toProcess: FileDropEntry = new FileDropEntry(path, item);
              this.zone.run(() => {
                this.addToQueue(toProcess);
              });
            } else {
              for (let i = 0; i < entries.length; i++) {
                this.zone.run(() => {
                  this.traverseFileTree(entries[i], path + entries[i].name);
                });
              }
            }
          } else {
            // continue with the reading
            entries = entries.concat(result);
            readEntries();
          }

          this.numOfActiveReadEntries--;
        });
      };

      readEntries();
    }
  }

  private async getFile(fileEntry: FileSystemFileEntry): Promise<File | void> {
    try {
      return await new Promise((resolve) => fileEntry.file(resolve));
    } catch (err) {
      console.log(err);
    }
  }

  private unzipFileQueueModal() {
    this.displayingModal = true;
    this.dialog
      .open<FileDropModalComponent>(FileDropModalComponent, {
        width: '50rem',
        maxHeight: '80vh',
        data: {
          title: 'Do you want to unzip all zipped files?',
        },
      })
      .afterClosed()
      .pipe(first())
      .subscribe(async (yes) => {
        if (this.filesToBeZippedQueue) {
          if (yes) {
            await this.unzipFiles();
          }
          if (!yes) {
            const fileList = this.filesToBeZippedQueue.reduce(
              this.transformFileToFileList,
              new DataTransfer()
            ).files;
            this.checkFiles(fileList);
          }

          this.zipReset();
        }
      });
  }

  private async unzipFiles() {
    const zippedFilesArray: File[] = [];
    for (let i = 0; i < this.filesToBeZippedQueue.length; i++) {
      const item = this.filesToBeZippedQueue[i];
      const zip = await this.zipFile.loadAsync(item);

      for (const zipFile in zip.files) {
        if (zip.file(zipFile)) {
          const blob = await zip.file(zipFile)?.async('blob');
          if (blob) {
            const file = new File([blob], this.filterDirPath(zipFile), {
              type: blob.type,
            });

            if (!this.checkOSHidden(file.name)) {
              zippedFilesArray.push(file);
            }
          }
        }
      }
    }
    const fileList = zippedFilesArray.reduce(
      this.transformFileToFileList,
      new DataTransfer()
    ).files;

    this.checkFiles(fileList);
  }

  private filterDirPath(filename: string) {
    if (this.checkOSHidden(filename)) {
      return filename;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return filename.split('/').pop()!;
  }

  private checkOSHidden(filename: string) {
    return filename.startsWith('__MACOSX');
  }

  /**
   * Clears any added files from the file input element so the same file can subsequently be added multiple times.
   */
  private resetFileInput(): void {
    if (this.fileSelector?.nativeElement) {
      const fileInputEl = this.fileSelector.nativeElement as HTMLInputElement;
      const fileInputContainerEl = fileInputEl.parentElement;
      const helperFormEl = this.getHelperFormElement();
      const fileInputPlaceholderEl = this.getFileInputPlaceholderElement();

      // Just a quick check so we do not mess up the DOM (will never happen though).
      if (fileInputContainerEl !== helperFormEl) {
        // Insert the form input placeholder in the DOM before the form input element.
        this.renderer.insertBefore(
          fileInputContainerEl,
          fileInputPlaceholderEl,
          fileInputEl
        );
        // Add the form input as child of the temporary form element, removing the form input from the DOM.
        this.renderer.appendChild(helperFormEl, fileInputEl);
        // Reset the form, thus clearing the input element of any files.
        helperFormEl.reset();
        // Add the file input back to the DOM in place of the file input placeholder element.
        this.renderer.insertBefore(
          fileInputContainerEl,
          fileInputEl,
          fileInputPlaceholderEl
        );
        // Remove the input placeholder from the DOM
        this.renderer.removeChild(fileInputContainerEl, fileInputPlaceholderEl);
      }
    }
  }

  private zipReset() {
    this.filesToBeZippedQueue = [];
    this.displayingModal = false;
  }

  /**
   * Get a cached HTML form element as a helper element to clear the file input element.
   */
  private getHelperFormElement(): HTMLFormElement {
    if (!this.helperFormEl) {
      this.helperFormEl = this.renderer.createElement(
        'form'
      ) as HTMLFormElement;
    }

    return this.helperFormEl;
  }

  /**
   * Get a cached HTML div element to be used as placeholder for the file input element when clearing said element.
   */
  private getFileInputPlaceholderElement(): HTMLDivElement {
    if (!this.fileInputPlaceholderEl) {
      this.fileInputPlaceholderEl = this.renderer.createElement(
        'div'
      ) as HTMLDivElement;
    }

    return this.fileInputPlaceholderEl;
  }

  private canGetAsEntry(item: any): item is DataTransferItem {
    return !!item.webkitGetAsEntry;
  }

  private fakeFileSystemFileEntry(file: File | DataTransferItem) {
    return {
      name: (file as File).name,
      isDirectory: false,
      isFile: true,
      file: <T>(callback: (filea: File) => T) => callback(file as File),
    } as FileSystemFileEntry;
  }

  private createFileDropEntryFromDTIorFile(item: File | DataTransferItem) {
    const fakeFileEntry = this.fakeFileSystemFileEntry(item);
    return new FileDropEntry(fakeFileEntry.name, fakeFileEntry);
  }

  private isDropzoneDisabled(): boolean {
    return this.globalDraggingInProgress || this.disabled;
  }

  private addToQueue(item: FileDropEntry): void {
    if (!item.fileEntry.isFile) {
      return;
    }
    (item.fileEntry as FileSystemFileEntry).file((file: File) => {
      if (this.isAccepted(file, this.accept)) {
        this.queue.push(item);
      }
    });
  }

  private preventAndStop(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  private transformFileToFileList(dataTransfer: DataTransfer, file: File) {
    dataTransfer.items.add(file);
    return dataTransfer;
  }
}
