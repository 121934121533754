import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { FileSystemFileEntry } from '../dom.types';
import { FileUploadTypes } from '../helpers/file-type';

export const unknownFileType = 'unknown';

@Component({
  selector: 'sk-file-icon',
  standalone: true,
  templateUrl: './file-icon.component.html',
  styleUrls: ['./file-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileIconComponent implements OnInit {
  @Input()
  public file!: FileSystemFileEntry;

  public fileType = unknownFileType;

  constructor(private cdr: ChangeDetectorRef) {}
  public ngOnInit(): void {
    this.file.file((file: File) => {
      this.fileType = this.getFileType(file);
      this.cdr.detectChanges();
    });
  }

  public getFileType(file: File): string {
    const matchedType = Object.keys(FileUploadTypes).find(
      (key) =>
        FileUploadTypes[key as keyof typeof FileUploadTypes] === file.type
    );
    return (
      FileUploadTypes[matchedType as keyof typeof FileUploadTypes] ||
      unknownFileType
    );
  }

  public isIcon(type: 'text' | 'audio' | 'video' | 'image'): boolean {
    switch (type) {
      case 'text':
        return (
          this.fileType === FileUploadTypes.CSV ||
          this.fileType === FileUploadTypes.DOCX
        );
      case 'audio':
        return (
          this.fileType === FileUploadTypes.AAC ||
          this.fileType === FileUploadTypes.MIDI
        );
      case 'image':
        return (
          this.fileType === FileUploadTypes.PNG ||
          this.fileType === FileUploadTypes.JPEG
        );
      case 'video':
        return (
          this.fileType === FileUploadTypes.AVI ||
          this.fileType === FileUploadTypes.MPEG
        );
    }
  }
}
