import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { BreadcrumbsService } from '@core/services/breadcrumbs.service';
import { SlideDefinitionLayoutService } from '@core/services/slide-definition-layout.service';

import { SlideDefinitionLayoutListDataSource } from './slide-definition-layout-list.dataSource';

@Component({
  selector: 'app-slide-definition-layout-list',
  templateUrl: './slide-definition-layout-list.component.html',
  styleUrls: [
    '../../partials/table/table.scss',
    '../../user/user-list/user-list-table.scss',
  ],
  standalone: true,
  imports: [MatMenu, MatMenuTrigger, MatTableModule, RouterLink, MatButton],
  providers: [SlideDefinitionLayoutService],
})
export class SlideDefinitionLayoutListComponent {
  dataSource = new SlideDefinitionLayoutListDataSource(
    this.slideDefinitionLayoutService
  );

  displayedColumns = ['displayName', 'actions'];

  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private slideDefinitionLayoutService: SlideDefinitionLayoutService
  ) {
    this.breadcrumbsService.set([
      { label: 'Slide Definition Layouts', link: '/slide-definition-layouts' },
    ]);
  }
}
