<div class="container u-marginTxxl u-flex u-marginBm">
  <div class="align-right">Feature Flag</div>
</div>
<br />

<div class="container u-marginTxxl">
  @if (error) {
    {{ error }}
  } @else if (form) {
    <form [formGroup]="form">
      <div class="u-marginBm">
        <mat-accordion multi>
          <!-- DATA -->
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title> Data </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- KEY ACTIVE DELETE -->
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-evenly;
              "
            >
              <div class="u-marginRxs" style="width: 100%">
                <mat-label class="o-form-label">Key</mat-label>
                <mat-form-field style="width: 100%">
                  <input
                    type="text"
                    matInput
                    formControlName="key"
                    placeholder="Key"
                    autocomplete="off"
                  />
                </mat-form-field>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;
                "
              >
                <mat-checkbox formControlName="active">Active </mat-checkbox>
                <button
                  mat-raised-button
                  color="primary"
                  (click)="deleteFeatureFlag()"
                >
                  Delete Feature Flag
                </button>
              </div>
            </div>

            <!-- DESCRIPTION -->
            <div class="u-width--full">
              <mat-label class="o-form-label">Description</mat-label>
              <mat-form-field style="width: 100%">
                <input
                  type="text"
                  matInput
                  formControlName="description"
                  placeholder="Description"
                />
              </mat-form-field>
            </div>
          </mat-expansion-panel>

          <!-- USERS -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Users </mat-panel-title>
              <mat-panel-description>
                Specify the list of users that should be allowed/disallowed to
                access the feature flag.
              </mat-panel-description>
            </mat-expansion-panel-header>

            <table
              mat-table
              [dataSource]="usersDataSource"
              class="u-marginBm"
              formGroupName="users"
            >
              <!-- Active Column -->
              <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef>Active</th>
                <td
                  mat-cell
                  *matCellDef="let user; let i = index"
                  [formGroupName]="i"
                >
                  <mat-checkbox formControlName="active" />
                </td>
              </ng-container>

              <!-- IdOrEmail switch -->
              <ng-container matColumnDef="idOrEmail">
                <th mat-header-cell *matHeaderCellDef>
                  Check a user by id or by email
                </th>
                <td
                  mat-cell
                  *matCellDef="let user; let i = index"
                  [formGroupName]="i"
                >
                  <mat-slide-toggle formControlName="idOrEmail" />
                  @if (isUserIdOrEmail(i)) {
                    by email
                  } @else {
                    by id
                  }
                </td>
              </ng-container>

              <!-- ID Column -->
              <ng-container matColumnDef="input">
                <th mat-header-cell *matHeaderCellDef></th>
                <td
                  mat-cell
                  *matCellDef="let user; let i = index"
                  [formGroupName]="i"
                >
                  <div class="u-flex">
                    @if (isUserIdOrEmail(i)) {
                      <mat-form-field
                        subscriptSizing="dynamic"
                        appearance="fill"
                        color="primary"
                        style="width: 350px"
                      >
                        <input
                          type="text"
                          matInput
                          formControlName="email"
                          placeholder="Email"
                          autocomplete="off"
                        />
                      </mat-form-field>
                      <mat-form-field
                        subscriptSizing="dynamic"
                        appearance="fill"
                        color="primary"
                        style="width: 150px"
                      >
                        <mat-select
                          formControlName="comparator"
                          placeholder="Comparator"
                        >
                          <mat-option value="exact">Exact</mat-option>
                          <mat-option value="regex">Regex</mat-option>
                        </mat-select>
                      </mat-form-field>
                    } @else {
                      <app-user-autocomplete-selectbox
                        formControlName="id"
                        style="width: 500px"
                      />
                    }
                  </div>
                </td>
              </ng-container>

              <!-- Actions Column -->
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td
                  mat-cell
                  *matCellDef="let user; let i = index"
                  [formGroupName]="i"
                >
                  <button mat-button color="warn" (click)="removeUser(i)">
                    Remove
                  </button>
                </td>
              </ng-container>

              <!-- Header & Row -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <button mat-raised-button color="primary" (click)="addUser()">
              Add User
            </button>
          </mat-expansion-panel>

          <!-- COHORTS -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Cohorts </mat-panel-title>
              <mat-panel-description>
                Specify the list of cohorts that should be applied to the
                feature flag.
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-accordion
              cdkDropList
              (cdkDropListDropped)="dropCohort($event)"
            >
              @for (
                cohort of form.get('cohorts')?.value || [];
                track cohort._id;
                let i = $index
              ) {
                <div
                  style="display: flex; gap: 10px; align-items: center"
                  cdkDrag
                >
                  <app-icon
                    cdkDragHandle
                    name="dots-six-vertical"
                    style="cursor: grab"
                  />
                  <mat-checkbox [formControl]="getCohortsFormActiveField(i)" />
                  <app-cohort-info
                    style="flex: 1"
                    [cohort]="cohortMap[cohort._id]"
                  />
                  <button mat-button color="warn" (click)="removeCohort(i)">
                    Remove
                  </button>
                </div>
              }
            </mat-accordion>
            <div style="margin-top: 10px">
              <mat-form-field subscriptSizing="dynamic" appearance="fill">
                <mat-select placeholder="Cohort" #selectedCohort>
                  @for (cohort of cohorts; track cohort._id; let i = $index) {
                    <mat-option [value]="cohort._id">
                      {{ cohort.name }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <button
                mat-raised-button
                color="primary"
                (click)="addCohort(selectedCohort.value)"
              >
                Add Cohort
              </button>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <br />
      <br />
      <button mat-raised-button color="primary" (click)="save()">
        Save Feature Flag
      </button>
    </form>
  } @else {
    loading...
  }
</div>
