import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FileSystemFileEntry } from '../dom.types';
import { FileIconComponent } from '../file-icon/file-icon.component';

@Component({
  selector: 'sk-file-list-item',
  standalone: true,
  imports: [FileIconComponent],
  templateUrl: './file-list-item.component.html',
  styleUrls: ['./file-list-item.component.scss'],
})
export class FileListItemComponent {
  @Input()
  public index!: number;

  @Input()
  public file!: FileSystemFileEntry;

  @Output()
  public removeFile = new EventEmitter<FileSystemFileEntry>();

  public handleRemoveFile(fileEntry: FileSystemFileEntry): void {
    this.removeFile.emit(fileEntry);
  }
}
