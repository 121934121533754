import { FileSystemEntry } from './dom.types';

/**
 * fileEntry is an instance of FileSystemFileEntry or FileSystemDirectoryEntry.
 * Which one is it can be checked using FileSystemEntry.isFile or FileSystemEntry.isDirectory
 * properties of the given FileSystemEntry.
 */
export class FileDropEntry {
  constructor(
    public relativePath: string,
    public fileEntry: FileSystemEntry
  ) {}
}
