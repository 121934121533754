<div class="search-container">
  <div>
    <mat-label class="form-label">Search</mat-label>
    <mat-form-field>
      <input
        [(ngModel)]="filters.search"
        (ngModelChange)="handleSearchChange($event)"
        type="text"
        matInput
        placeholder="Search"
      />
      @if (filters.search) {
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearSearch()"
        >
          ✖️
        </button>
      }
    </mat-form-field>
  </div>

  <div>
    <mat-label class="form-label">Access Category</mat-label>
    <mat-form-field>
      <mat-select
        [value]="filters.accessCategory"
        (selectionChange)="handleAccessCategoryChange($event)"
      >
        <mat-option value="">All</mat-option>
        @for (type of accessCategories | keyvalue; track type) {
          <mat-option value="{{ type.value }}">
            {{ type.value }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <mat-checkbox
      [(ngModel)]="filters.garboSceneLocation"
      (ngModelChange)="handleGarboSceneLocationChange($event)"
      >Garbo compatible
    </mat-checkbox>
  </div>

  <div class="align-right">
    <button mat-raised-button color="primary" [matMenuTriggerFor]="menu">
      Slide definition actions
    </button>
    <mat-menu #menu="matMenu">
      <a [routerLink]="['/slide-definitions/add']" mat-button>
        🎨
        <span>Create slide definition</span>
      </a>
    </mat-menu>
  </div>
</div>

<div class="container list-container">
  <div class="list-container-page">
    <h3>Slide Definitions</h3>
    <mat-table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
      <ng-container matColumnDef="displayName">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let slideDefinition">
          <a [routerLink]="['/slide-definitions', slideDefinition._id]">
            {{ slideDefinition.displayName }}
          </a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="accessCategory">
        <mat-header-cell *matHeaderCellDef>Access Category</mat-header-cell>
        <mat-cell *matCellDef="let slideDefinition"
          >{{ slideDefinition.accessCategory }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
        <mat-cell *matCellDef="let slideDefinition">
          <a
            [routerLink]="['/slide-definitions', slideDefinition._id]"
            mat-raised-button
            color="primary"
          >
            Edit
          </a>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns" />
      <mat-row *matRowDef="let row; columns: displayedColumns" />
    </mat-table>
    <mat-paginator
      [length]="dataSource.totalCount$ | async"
      [pageSize]="(dataSource.filters$ | async)?.pageSize"
      [pageSizeOptions]="[25, 50, 100]"
      [pageIndex]="(dataSource.filters$ | async)?.pageIndex"
      (page)="handlePageChange($event)"
    />
  </div>
</div>
