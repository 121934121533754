import { JsonPipe, TitleCasePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Signal,
  TemplateRef,
  inject,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import {
  takeUntilDestroyed,
  toObservable,
  toSignal,
} from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  NgForm,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MatAutocompleteModule,
  MatOption,
} from '@angular/material/autocomplete';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import {
  MatError,
  MatFormField,
  MatHint,
  MatLabel,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { environment } from '@environment';
import { Client } from '@models/client';
import {
  AssetLibraryForFeed,
  FeedsService,
} from '@views/agency/agency/feeds/feeds.service';
import { combineLatest, concat, forkJoin, of } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';

import { MAX_TITLE_LENGTH } from '@storykit/constants';
import { Campaign } from '@storykit/typings/src/coen';
import { FeedView } from '@storykit/typings/src/cws/feed';

const uuidRegex =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;

@Component({
  selector: 'app-add-feed-form',
  standalone: true,
  imports: [
    JsonPipe,
    MatButton,
    MatCheckbox,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatError,
    MatFormField,
    MatHint,
    MatInput,
    MatLabel,
    MatOption,
    MatProgressBar,
    MatSelect,
    MatTooltip,
    ReactiveFormsModule,
    MatDialogClose,
    TitleCasePipe,
    MatAutocompleteModule,
  ],
  templateUrl: './add-feed-form.component.html',
  styleUrl: './add-feed-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddFeedFormComponent {
  agencyId = input.required<string>();
  clients = input.required<Client[]>();

  feedCreated = output<FeedView>();

  addNgForm = viewChild.required<NgForm>('addNgForm');

  service = inject(FeedsService);
  destroyRef = inject(DestroyRef);
  dialog = inject(MatDialog);

  addFeedForm = new FormGroup<{
    clientId: FormControl<string>;
    name: FormControl<string>;
    feedUrl: FormControl<string>;
    themeId: FormControl<string>;
    campaignId: FormControl<string>;
    modularSlideEnabled: FormControl<boolean>;
    notificationEmail: FormControl<string>;
    assetLibraryId: FormControl<string | null>;
    sourceLanguage: FormControl<string | null>;
    outputLanguage: FormControl<string | null>;
  }>({
    clientId: new FormControl('', {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.minLength(24),
        Validators.maxLength(24),
      ],
    }),
    name: new FormControl('', {
      validators: [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(MAX_TITLE_LENGTH),
      ],
      nonNullable: true,
    }),
    feedUrl: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    themeId: new FormControl('', {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.minLength(24),
        Validators.maxLength(24),
      ],
    }),
    campaignId: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.pattern(uuidRegex)],
    }),
    modularSlideEnabled: new FormControl(false, { nonNullable: true }),
    notificationEmail: new FormControl('', { nonNullable: true }),
    assetLibraryId: new FormControl<string | null>(null, {
      validators: [Validators.minLength(24), Validators.maxLength(24)],
    }),
    sourceLanguage: new FormControl<string | null>(null),
    outputLanguage: new FormControl<string | null>(null),
  });

  private selectedClient$ = concat(
    of(this.addFeedForm.controls.clientId.value),
    this.addFeedForm.controls.clientId.valueChanges.pipe()
  );

  themes = toSignal(
    this.selectedClient$.pipe(
      switchMap((clientId) => {
        if (!clientId) {
          return [];
        }
        return this.service.getThemes(clientId);
      })
    ),
    {
      initialValue: [],
    }
  );
  loading = signal<boolean>(false);

  campaigns: Signal<Campaign[]> = toSignal(
    combineLatest([toObservable(this.agencyId), this.selectedClient$]).pipe(
      switchMap(([agencyId, clientId]) =>
        this.service.getCampaigns(agencyId, clientId)
      ),
      map((campaigns) =>
        campaigns.sort((a, b) => {
          if (a.isCustom !== b.isCustom) {
            return Number(b.isCustom) - Number(a.isCustom);
          }
          return a.name.localeCompare(b.name);
        })
      )
    ),
    {
      initialValue: [],
    }
  );

  assetLibraries: Signal<AssetLibraryForFeed[]> = toSignal(
    this.selectedClient$.pipe(
      switchMap((clientId) => {
        if (!clientId) {
          return [];
        }
        return forkJoin({
          clientLibraries: this.service.getClientAssetLibraries(clientId),
          agencyLibraries: this.service.getAgencyAssetLibraries(
            this.agencyId()
          ),
        }).pipe(
          map(({ clientLibraries, agencyLibraries }) => {
            return clientLibraries.concat(agencyLibraries);
          })
        );
      })
    ),
    {
      initialValue: [],
    }
  );

  languages = toSignal(this.service.getLanguages(), {
    initialValue: [],
  });

  submitAddFeed(
    successDialog: TemplateRef<{ url: string }>,
    errorDialog: TemplateRef<{ error: unknown }>
  ) {
    if (!this.addFeedForm.valid) {
      return;
    }

    this.loading.set(true);

    const fields = this.addFeedForm.controls;
    this.service
      .createFeed(fields.clientId.value, {
        feedUrl: fields.feedUrl.value,
        name: fields.name.value,
        themeId: fields.themeId.value,
        coenCampaignId: fields.campaignId.value,
        notificationRecipients: fields.notificationEmail.value
          .split(/\s*,\s*/)
          .filter((value) => value.trim().length > 0),
        assetLibraryId: this.optionalString(fields.assetLibraryId),
        sourceLanguage: this.optionalString(fields.sourceLanguage),
        outputLanguage: this.optionalString(fields.outputLanguage),
        renderModularSlides: fields.modularSlideEnabled.value,
      })
      .pipe(
        finalize(() => this.loading.set(false)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: (feed: FeedView) => {
          this.dialog.open(successDialog, {
            data: {
              url:
                environment.create.link +
                '/clients/' +
                feed.clientId +
                '/feeds/' +
                feed._id,
            },
          });

          this.feedCreated.emit(feed);
          this.addFeedForm.reset();
          this.addNgForm().resetForm(); // https://github.com/angular/components/issues/4190#issuecomment-304889028
        },
        error: (error: HttpErrorResponse) => {
          this.dialog.open(errorDialog, {
            data: {
              error: error.error,
            },
          });
        },
      });
  }

  private optionalString(
    field: FormControl<string | null>
  ): string | undefined {
    const value = field.value;
    if (value === null) {
      return undefined;
    }
    if (value.trim().length <= 0) {
      return undefined;
    }
    return value;
  }
}
