<div class="container u-marginTxxl u-flex u-marginBm">
  <div class="align-right">Cohort</div>
</div>
<br />

<div class="container u-marginTxxl">
  @if (error) {
    {{ error }}
  } @else if (form) {
    <form [formGroup]="form">
      <div class="u-marginBm">
        <mat-accordion multi>
          <!-- DATA -->
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title> Data </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- NAME DELETE -->
            <div style="display: flex">
              <div class="u-marginRxs" style="width: 100%">
                <mat-label class="o-form-label">Name</mat-label>
                <mat-form-field style="width: 100%">
                  <input
                    type="text"
                    matInput
                    formControlName="name"
                    placeholder="Name"
                    autocomplete="off"
                  />
                </mat-form-field>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: end;
                  width: 100%;
                "
              >
                <button
                  mat-raised-button
                  color="primary"
                  (click)="deleteCohort()"
                >
                  Delete Cohort
                </button>
              </div>
            </div>

            <!-- DESCRIPTION -->
            <div class="u-width--full">
              <mat-label class="o-form-label">Description</mat-label>
              <mat-form-field style="width: 100%">
                <input
                  type="text"
                  matInput
                  formControlName="description"
                  placeholder="Description"
                />
              </mat-form-field>
            </div>
          </mat-expansion-panel>

          <!-- USERS -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Users </mat-panel-title>
              <mat-panel-description>
                Specify the list of users that should belong to the cohort?
              </mat-panel-description>
            </mat-expansion-panel-header>

            <table
              mat-table
              [dataSource]="usersDataSource"
              class="u-marginBm"
              formGroupName="users"
            >
              <!-- IdOrEmail switch -->
              <ng-container matColumnDef="idOrEmail">
                <th mat-header-cell *matHeaderCellDef>
                  Check a user by id or by email
                </th>
                <td
                  mat-cell
                  *matCellDef="let user; let i = index"
                  [formGroupName]="i"
                >
                  <mat-slide-toggle formControlName="idOrEmail" />
                  @if (isUserIdOrEmail(i)) {
                    by email
                  } @else {
                    by id
                  }
                </td>
              </ng-container>

              <!-- ID Column -->
              <ng-container matColumnDef="input">
                <th mat-header-cell *matHeaderCellDef></th>
                <td
                  mat-cell
                  *matCellDef="let user; let i = index"
                  [formGroupName]="i"
                >
                  <div class="u-flex">
                    @if (isUserIdOrEmail(i)) {
                      <mat-form-field
                        subscriptSizing="dynamic"
                        appearance="fill"
                        color="primary"
                        style="width: 350px"
                      >
                        <input
                          type="text"
                          matInput
                          formControlName="email"
                          placeholder="Email"
                          autocomplete="off"
                        />
                      </mat-form-field>
                      <mat-form-field
                        subscriptSizing="dynamic"
                        appearance="fill"
                        color="primary"
                        style="width: 150px"
                      >
                        <mat-select
                          formControlName="comparator"
                          placeholder="Comparator"
                        >
                          <mat-option value="exact">Exact</mat-option>
                          <mat-option value="regex">Regex</mat-option>
                        </mat-select>
                      </mat-form-field>
                    } @else {
                      <app-user-autocomplete-selectbox
                        formControlName="id"
                        style="width: 500px"
                      />
                    }
                  </div>
                </td>
              </ng-container>

              <!-- Actions Column -->
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td
                  mat-cell
                  *matCellDef="let user; let i = index"
                  [formGroupName]="i"
                >
                  <button mat-button color="warn" (click)="removeUser(i)">
                    Remove
                  </button>
                </td>
              </ng-container>

              <!-- Header & Row -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <button mat-raised-button color="primary" (click)="addUser()">
              Add User
            </button>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <br /><br />
      <button mat-raised-button color="primary" (click)="save()">
        Save Cohort
      </button>
    </form>
  } @else {
    loading...
  }
</div>
