/**
 * This table lists some important MIME types for the Web copied from
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
 * http://www.iana.org/assignments/media-types/media-types.xhtml
 */
export enum FileUploadTypes {
  ARC = 'application/octet-stream',
  WOFF2 = 'font/woff2',
  OTF = 'font/otf',
  TTF = 'font/ttf',
  GZ = 'application/x-gzip',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  TIF = 'image/tiff',
  CSV = 'text/csv',
  WOFF = 'application/x-font-woff',
  ZIP = 'application/zip',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  AAC = 'audio/x-aac',
  MIDI = 'audio/midi',
  AVI = 'video/x-msvideo',
  MPEG = 'video/mpeg',
}
