<div [formGroup]="accessForm" class="access-section">
  <div>
    @if (accessForm.value.access?.length === 0) {
      <div class="no-access">No access added</div>
    }

    @for (access of accessEntryControls; track access; let i = $index) {
      <div formArrayName="access">
        <div class="access-row" [formGroupName]="i">
          <div>
            <mat-label class="form-label">Access type</mat-label>
            <mat-form-field
              subscriptSizing="dynamic"
              class="margin-right-small"
            >
              <mat-select
                (selectionChange)="onAccessTypeChange(i)"
                formControlName="type"
              >
                @for (accessType of accessTypes; track accessType) {
                  <mat-option value="{{ accessType }}">{{
                    accessTypeLabels[accessType]
                  }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          @if (access.value.type === 'client') {
            <div class="margin-left-large">
              <mat-label class="form-label">Client</mat-label>
              <mat-form-field
                subscriptSizing="dynamic"
                class="margin-right-small"
              >
                <mat-select formControlName="id" placeholder="Client">
                  <mat-option>
                    <ngx-mat-select-search
                      formControlName="search"
                      [hideClearSearchButton]="true"
                      placeholderLabel="Client"
                      noEntriesFoundLabel="No matching client found"
                    />
                  </mat-option>
                  @for (client of getFilteredClients(i); track client) {
                    <mat-option value="{{ client._id }}">
                      {{ client.name }} ({{ client._id }})
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          }
          @if (access.value.type === 'agency') {
            <div class="margin-left-large">
              <mat-label class="form-label">Agency</mat-label>
              <mat-form-field
                subscriptSizing="dynamic"
                class="margin-right-small"
              >
                <mat-select formControlName="id" placeholder="Agency">
                  <mat-option>
                    <ngx-mat-select-search
                      formControlName="search"
                      [hideClearSearchButton]="true"
                      placeholderLabel="Agency"
                      noEntriesFoundLabel="No matching agency found"
                    />
                  </mat-option>
                  @for (agency of getFilteredAgencies(i); track agency) {
                    <mat-option value="{{ agency._id }}">
                      {{ agency.name }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          }
          <div class="delete-row">
            <button mat-icon-button (click)="deleteAccessRow(i)">🗑️</button>
          </div>
        </div>
      </div>
    }

    <button mat-flat-button (click)="createAccessRow()" color="primary">
      Add Access
    </button>
  </div>
</div>
