<mat-table
  mat-table
  [dataSource]="usersDataSource()"
  [skElementId]="elementId.Admin.UserTable"
  class="mat-elevation-z0"
>
  <ng-container matColumnDef="firstName">
    <mat-header-cell *matHeaderCellDef> First name </mat-header-cell>
    <mat-cell
      *matCellDef="let user"
      [skElementId]="elementId.Admin.UserTableFirstName"
      >{{ user.firstName }}</mat-cell
    >
  </ng-container>

  <ng-container matColumnDef="lastName">
    <mat-header-cell *matHeaderCellDef> Last name </mat-header-cell>
    <mat-cell
      *matCellDef="let user"
      [skElementId]="elementId.Admin.UserTableLastName"
      >{{ user.lastName }}</mat-cell
    >
  </ng-container>

  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
    <mat-cell
      *matCellDef="let user"
      [skElementId]="elementId.Admin.UserTableEmail"
      >{{ user.email }}</mat-cell
    >
  </ng-container>

  <ng-container matColumnDef="role">
    <mat-header-cell *matHeaderCellDef> Role </mat-header-cell>
    <mat-cell
      *matCellDef="let user"
      [skElementId]="elementId.Admin.UserTableRole"
    >
      {{ userRoleLabels[assertUser(user).role] }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
    <mat-cell *matCellDef="let user">
      <a
        [routerLink]="['/users', user._id]"
        mat-flat-button
        color="primary"
        [skElementId]="elementId.Admin.UserTableEditButton"
      >
        Edit
      </a>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="blocked">
    <mat-header-cell *matHeaderCellDef />
    <mat-cell
      *matCellDef="let user"
      [skElementId]="elementId.Admin.UserTableBlocked"
    >
      {{ user.blocked ? 'Blocked' : '' }}
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns" />
  <mat-row
    *matRowDef="let row; columns: displayedColumns"
    [skElementId]="elementId.Admin.UserTableRow"
    [skElementIdIndex]="row.index"
  />
</mat-table>

<mat-paginator
  [length]="(usersDataSource().users$ | async)?.totalCount"
  [pageSize]="(usersDataSource().filters$ | async)?.pageSize"
  [pageSizeOptions]="[25, 50, 100]"
  [pageIndex]="((usersDataSource().filters$ | async)?.pageIndex || 0) - 1"
  (page)="usersDataSource().updatePage($event)"
/>
