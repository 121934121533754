<div [formGroup]="form" class="access-section">
  @if (clientsAgencies$ | async) {
    <div>
      @for (access of accessControls$ | async; track access; let i = $index) {
        <div formArrayName="access">
          <div class="access-form-row" [formGroupName]="i">
            <div>
              <mat-label class="form-label">Access type</mat-label>
              <mat-form-field>
                <mat-select
                  (selectionChange)="onAccessTypeChange(i)"
                  formControlName="type"
                  [skElementId]="elementId.Admin.AccessTypeSelection"
                >
                  @for (
                    accessType of accessTypes;
                    track accessType;
                    let i = $index
                  ) {
                    <mat-option
                      value="{{ accessType }}"
                      [skElementId]="elementId.Admin.AccessTypeSelectionOption"
                      [skElementIdIndex]="i"
                      >{{ accessTypeLabels[accessType] }}</mat-option
                    >
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-label class="form-label">Role</mat-label>
              <mat-form-field>
                <mat-select
                  formControlName="role"
                  [skElementId]="elementId.Admin.AccessRoleSelection"
                >
                  @for (
                    accessRole of accessRoles;
                    track accessRole;
                    let i = $index
                  ) {
                    <mat-option
                      value="{{ accessRole }}"
                      [skElementId]="elementId.Admin.AccessRoleSelectionOption"
                      [skElementIdIndex]="i"
                      >{{ roleLabels[accessRole] }}</mat-option
                    >
                  }
                </mat-select>
              </mat-form-field>
            </div>
            @if (access.value.type === 'client') {
              <div>
                <mat-label class="form-label">Agency</mat-label>
                <mat-form-field>
                  <mat-select
                    #agencySelect
                    formControlName="agencyId"
                    placeholder="Agency"
                    (selectionChange)="onAccessTypeChange(i)"
                    (openedChange)="onOpenedChange($event, i)"
                    [skElementId]="elementId.Admin.AccessAgencySelection"
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        formControlName="agencyFilter"
                        [hideClearSearchButton]="true"
                        placeholderLabel="Agency"
                        noEntriesFoundLabel="No matching agency found"
                      />
                    </mat-option>
                    @if (!agencySelect.panelOpen) {
                      @if (getAgency(i); as agency) {
                        <mat-option
                          [value]="agency._id"
                          [skElementId]="
                            elementId.Admin.AccessAgencySelectionOption
                          "
                          [skElementIdIndex]="0"
                        >
                          {{ agency.name }} [{{ agency.customerType }}]
                        </mat-option>
                      }
                    }
                    <cdk-virtual-scroll-viewport
                      #agencyScroll
                      [itemSize]="42"
                      [minBufferPx]="42 * 5 * 2"
                      [maxBufferPx]="42 * 5 * 2"
                      class="agency-select"
                    >
                      <mat-option
                        *cdkVirtualFor="
                          let agency of getFilteredAgencies(i);
                          let i = index
                        "
                        [value]="agency._id"
                        [skElementId]="
                          elementId.Admin.AccessAgencySelectionOption
                        "
                        [skElementIdIndex]="i"
                      >
                        {{ agency.name }} [{{ agency.customerType }}]
                      </mat-option>
                    </cdk-virtual-scroll-viewport>
                  </mat-select>
                </mat-form-field>
              </div>
            }
            @if (access.value.agencyId && access.value.type === 'client') {
              <div>
                <mat-label class="form-label">Client</mat-label>
                <mat-form-field>
                  <mat-select
                    formControlName="id"
                    [skElementId]="elementId.Admin.AccessClientSelection"
                  >
                    @for (
                      client of getClientAgencies$(i) | async;
                      track client;
                      let i = $index
                    ) {
                      <mat-option
                        value="{{ client._id }}"
                        [skElementId]="
                          elementId.Admin.AccessClientSelectionOption
                        "
                        [skElementIdIndex]="i"
                        >{{ client.name }}</mat-option
                      >
                    }
                  </mat-select>
                  @if ((clientsAgencies$ | async) === null) {
                    <mat-label class="loader">
                      <mat-spinner class="prefix" [diameter]="18" />
                    </mat-label>
                  }
                </mat-form-field>
              </div>
            }
            @if (access.value.type === 'agency') {
              <div>
                <mat-label class="form-label">Agency</mat-label>
                <mat-form-field>
                  <mat-select
                    #agencySelect
                    formControlName="id"
                    placeholder="Agency"
                    [skElementId]="elementId.Admin.AccessOnlyAgencySelection"
                    (openedChange)="onOpenedChange($event, i, 'id')"
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        formControlName="agencyFilter"
                        [hideClearSearchButton]="true"
                        placeholderLabel="Agency"
                        noEntriesFoundLabel="No matching agency found"
                      />
                    </mat-option>
                    @if (!agencySelect.panelOpen) {
                      @if (getAgency(i, 'id'); as agency) {
                        <mat-option
                          [value]="agency._id"
                          [skElementId]="
                            elementId.Admin.AccessOnlyAgencySelectionOption
                          "
                          [skElementIdIndex]="0"
                        >
                          {{ agency.name }} [{{ agency.customerType }}]
                        </mat-option>
                      }
                    }
                    <cdk-virtual-scroll-viewport
                      #agencyScroll
                      [itemSize]="42"
                      [minBufferPx]="42 * 5 * 2"
                      [maxBufferPx]="42 * 5 * 2"
                      class="agency-select"
                    >
                      <mat-option
                        *cdkVirtualFor="
                          let agency of getFilteredAgencies(i);
                          let i = index
                        "
                        [value]="agency._id"
                        [skElementId]="
                          elementId.Admin.AccessOnlyAgencySelectionOption
                        "
                        [skElementIdIndex]="i"
                      >
                        {{ agency.name }} [{{ agency.customerType }}]
                      </mat-option>
                    </cdk-virtual-scroll-viewport>
                  </mat-select>
                </mat-form-field>
              </div>
            }
            <div class="delete-row">
              <button
                mat-icon-button
                (click)="deleteAccessRow(i)"
                [skElementId]="elementId.Admin.AccessDeleteButton"
              >
                🗑️
              </button>
            </div>
          </div>
        </div>
      } @empty {
        <div class="no-access">No access added</div>
      }
      <button
        mat-flat-button
        (click)="createAccessRow()"
        [skElementId]="elementId.Admin.AddAccessButton"
        color="primary"
      >
        Add Access
      </button>
    </div>
  }
</div>
