<div
  [className]="dropZoneClassName"
  [ngClass]="{
    'file-drop__drop-zone--over': isDraggingOverDropZone,
  }"
  (drop)="dropFiles($event)"
  (dragover)="onDragOver($event)"
  (dragenter)="onDragEnter($event)"
  (dragleave)="onDragLeave($event)"
>
  <div [className]="contentClassName">
    <input
      type="file"
      #fileSelector
      [accept]="accept"
      [attr.directory]="directory || undefined"
      [attr.webkitdirectory]="directory || undefined"
      [attr.mozdirectory]="directory || undefined"
      [attr.msdirectory]="directory || undefined"
      [attr.odirectory]="directory || undefined"
      [multiple]="multiple"
      (change)="dropFileChange($event)"
      class="file-drop__file-input"
    />

    <ng-template #defaultContentTemplate>
      @if (!isLoading) {
        <div class="label-container">
          <div class="label-row">
            @if (dropZoneLabel) {
              <div class="file-drop__drop-zone-label">
                {{ dropZoneLabel }}
              </div>
            }
            @if (showBrowseBtn) {
              <button
                [className]="browseBtnClassName"
                (click)="openFileSelector($event)"
              >
                {{ browseBtnLabel }}
              </button>
            }
          </div>
          @if (!showBrowseBtn) {
            <div class="icon-container" (click)="handleUploadIconClick()">
              📤
            </div>
          }
        </div>
      } @else {
        <div class="-square flex -h-center -v-center">
          <mat-spinner diameter="50" />
        </div>
      }
    </ng-template>

    <ng-template
      [ngTemplateOutlet]="contentTemplate || defaultContentTemplate"
      [ngTemplateOutletContext]="{ openFileSelector: openFileSelector }"
    />
  </div>
  <!-- Files list -->
  <ng-template #defaultFileListTemplate let-files="files">
    @if (files) {
      <div class="files-list">
        @for (item of files; track item; let i = $index) {
          <sk-file-list-item
            [file]="item"
            [index]="i"
            (removeFile)="onRemoveFile($event)"
          />
        }
      </div>
    }
  </ng-template>

  <ng-template
    [ngTemplateOutlet]="fileListTemplate || defaultFileListTemplate"
    [ngTemplateOutletContext]="{ files: fileEntries$ | async }"
  />
</div>
