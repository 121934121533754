<div class="user-container">
  @if ((loading$ | async) === false) {
    <form [formGroup]="form">
      <h2>User {{ blocked ? '- Blocked' : '' }}</h2>
      <app-user-form
        [form]="form"
        [verified]="verified"
        (resendInviteEmail)="resendInviteEmail()"
      />
      <mat-tab-group animationDuration="0ms" class="section-divider">
        <mat-tab>
          <ng-template mat-tab-label>
            <div [skElementId]="elementId.Admin.UserMenuItemAccess">
              Access ({{ accessCount }})
            </div>
          </ng-template>
          <div class="user-access">
            <app-user-access-form
              [form]="accessForm"
              [clientsAgencies$]="clientsAgencies$"
              [values$]="accessValues$"
            />
          </div>
        </mat-tab>
        @switch (mode$ | async) {
          @case (modes.Edit) {
            <mat-tab>
              <ng-template mat-tab-label>
                <div [skElementId]="elementId.Admin.UserMenuItemAdvanced">
                  Advanced
                </div>
              </ng-template>
              <div class="advanced">
                <app-advanced-section
                  [form]="form"
                  [formLoading]="formLoading"
                  [userId$]="userId$"
                  [resetLink$]="resetLink$"
                  [ipBlock$]="ipBlock$"
                  [blocked]="blocked"
                  [flaggedForDeletion]="flaggedForDeletion"
                  (resetPassword)="getResetLink()"
                  (block)="openBlockDialog()"
                  (delete)="openDeleteDialog()"
                  (checkIpBlock)="checkIpBlock()"
                  (removeIpBlock)="removeIpBlock()"
                  (removeDeletedStatus)="removeDeletedStatus()"
                />
              </div>
            </mat-tab>
          }
        }
      </mat-tab-group>
      @switch (mode$ | async) {
        @case (modes.Add) {
          <button
            class="submit-button"
            [disabled]="formLoading || form.invalid"
            mat-flat-button
            data-test-id="create-user-button"
            color="primary"
            (click)="createUser()"
            [skElementId]="elementId.Admin.UserCreateButton"
          >
            Create User
          </button>
        }
        @case (modes.Edit) {
          <button
            class="submit-button"
            [disabled]="formLoading || form.invalid"
            mat-flat-button
            data-test-id="update-user-button"
            color="primary"
            (click)="updateUser()"
            [skElementId]="elementId.Admin.UserUpdateButton"
          >
            Update User
          </button>
        }
      }
    </form>
  }
  <app-error [observable$]="user$" />
</div>
