import { Injectable } from '@angular/core';

import { FEATURE } from '@storykit/constants';
import { Cws } from '@storykit/typings';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  private userActiveFeatureFlags: string[] = [];

  public init(user: Cws.User) {
    if (user.activeFeatureFlags) {
      this.userActiveFeatureFlags = user.activeFeatureFlags;
    }
  }

  public isFeatureEnabled(featureKey: FEATURE): boolean {
    return this.userActiveFeatureFlags.includes(featureKey);
  }
}
