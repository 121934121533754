@switch (true) {
  @default {
    📁
  }
  @case (isIcon('image')) {
    🖼️
  }
  @case (isIcon('text')) {
    📝
  }
  @case (isIcon('audio')) {
    🎵
  }
  @case (isIcon('video')) {
    🎥
  }
}
