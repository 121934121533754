@if (loading$ | async) {
  Loading..
} @else {
  <form [formGroup]="slideDefinitionLayoutForm">
    <div
      class="slide-definition-layout-header-container container container--small"
    >
      <div class="slide-definition-layout-header">
        <h1>Slide Definition</h1>
      </div>
    </div>

    <div class="container container--small">
      <div class="slide-definition-layout-section">
        <div>
          <mat-label class="form-label">Display name</mat-label>
          <mat-form-field>
            <input
              type="text"
              matInput
              formControlName="displayName"
              placeholder="Display name"
              autocomplete="off"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="slide-definition-layout-section">
        <div class="u-width--editor">
          <mat-label class="form-label">Layout</mat-label>
          <mat-form-field>
            <textarea
              matInput
              formControlName="layout"
              placeholder="{}"
              autocomplete="off"
            ></textarea>
            @if (
              slideDefinitionLayoutForm.get('layout')?.hasError('jsonInvalid')
            ) {
              <mat-error>Invalid JSON</mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="button-container container container--small">
      @if ((mode$ | async) === modes.Add) {
        <button
          [disabled]="formLoading || slideDefinitionLayoutForm.invalid"
          mat-flat-button
          color="primary"
          (click)="createSlideDefinitionLayout()"
        >
          Create Slide Definition Layout
        </button>
      } @else if ((mode$ | async) === modes.Edit) {
        <button
          [disabled]="formLoading || slideDefinitionLayoutForm.invalid"
          mat-flat-button
          color="primary"
          (click)="updateSlideDefinitionLayout()"
        >
          Update Slide Definitition Layout
        </button>
        <button
          mat-flat-button
          [disabled]="formLoading"
          color="warn"
          (click)="openDeleteDialog()"
        >
          Delete Slide Definition Layout
        </button>
      }
    </div>
  </form>
}
<app-error [observable$]="slideDefinitionLayout$" />
