import { JsonPipe, TitleCasePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  Signal,
  TemplateRef,
  computed,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import {
  takeUntilDestroyed,
  toObservable,
  toSignal,
} from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MatAutocompleteModule,
  MatOption,
} from '@angular/material/autocomplete';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import {
  MatError,
  MatFormField,
  MatHint,
  MatLabel,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import {
  AssetLibraryForFeed,
  FeedsService,
} from '@views/agency/agency/feeds/feeds.service';
import { combineLatest, forkJoin } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';

import { MAX_TITLE_LENGTH } from '@storykit/constants';
import { Campaign } from '@storykit/typings/src/coen';
import { FeedMetadata, FeedView } from '@storykit/typings/src/cws/feed';

const uuidRegex =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;

@Component({
  selector: 'app-edit-feed-form',
  standalone: true,
  imports: [
    JsonPipe,
    MatButton,
    MatCheckbox,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatError,
    MatFormField,
    MatHint,
    MatInput,
    MatLabel,
    MatOption,
    MatProgressBar,
    MatSelect,
    ReactiveFormsModule,
    MatDialogClose,
    MatTooltip,
    TitleCasePipe,
    MatAutocompleteModule,
  ],
  templateUrl: './edit-feed-form.component.html',
  styleUrl: './edit-feed-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditFeedFormComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    this.elementRef.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
  agencyId = input.required<string>();
  feedToEdit = input.required<FeedMetadata>();

  clientId = computed(() => this.feedToEdit().clientId);

  feedUpdated = output<FeedView>();
  canceled = output<void>();

  service = inject(FeedsService);
  destroyRef = inject(DestroyRef);
  dialog = inject(MatDialog);
  elementRef = inject(ElementRef<HTMLElement>);

  formGroup: Signal<
    FormGroup<{
      name: FormControl<string>;
      feedUrl: FormControl<string>;
      themeId: FormControl<string>;
      campaignId: FormControl<string>;
      modularSlideEnabled: FormControl<boolean>;
      notificationEmail: FormControl<string>;
      assetLibraryId: FormControl<string | null>;
      sourceLanguage: FormControl<string | null>;
      outputLanguage: FormControl<string | null>;
    }>
  > = computed(() => {
    const feedToEdit = this.feedToEdit();
    const feedSettings = feedToEdit.settings;
    return new FormGroup({
      name: new FormControl(feedSettings.name, {
        validators: [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(MAX_TITLE_LENGTH),
        ],
        nonNullable: true,
      }),
      feedUrl: new FormControl(feedSettings.feedUrl, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      themeId: new FormControl(feedSettings.themeId, {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.minLength(24),
          Validators.maxLength(24),
        ],
      }),
      campaignId: new FormControl(feedSettings.coenCampaignId, {
        nonNullable: true,
        validators: [Validators.required, Validators.pattern(uuidRegex)],
      }),
      modularSlideEnabled: new FormControl(
        feedSettings.renderModularSlides || false,
        { nonNullable: true }
      ),
      notificationEmail: new FormControl(
        feedSettings.notificationRecipients.join(',\n'),
        {
          nonNullable: true,
        }
      ),
      assetLibraryId: new FormControl<string | null>(
        feedSettings.assetLibraryId ?? null,
        {
          validators: [Validators.minLength(24), Validators.maxLength(24)],
        }
      ),
      sourceLanguage: new FormControl<string | null>(
        feedSettings.sourceLanguage ?? null
      ),
      outputLanguage: new FormControl<string | null>(
        feedSettings.outputLanguage ?? null
      ),
    });
  });

  themes = toSignal(
    toObservable(this.clientId).pipe(
      switchMap((clientId) => {
        return this.service.getThemes(clientId);
      })
    ),
    {
      initialValue: [],
    }
  );
  loading = signal<boolean>(false);
  languages = toSignal(this.service.getLanguages(), {
    initialValue: [],
  });

  campaigns: Signal<Campaign[]> = toSignal(
    combineLatest([
      toObservable(this.agencyId),
      toObservable(this.clientId),
    ]).pipe(
      switchMap(([agencyId, clientId]) =>
        this.service.getCampaigns(agencyId, clientId)
      ),
      map((campaigns) =>
        campaigns.sort((a, b) => {
          if (a.isCustom !== b.isCustom) {
            return Number(b.isCustom) - Number(a.isCustom);
          }
          return a.name.localeCompare(b.name);
        })
      )
    ),
    {
      initialValue: [],
    }
  );

  assetLibraries: Signal<AssetLibraryForFeed[]> = toSignal(
    toObservable(this.clientId).pipe(
      switchMap((clientId) => {
        return forkJoin({
          clientLibraries: this.service.getClientAssetLibraries(clientId),
          agencyLibraries: this.service.getAgencyAssetLibraries(
            this.agencyId()
          ),
        }).pipe(
          map(({ clientLibraries, agencyLibraries }) => {
            return clientLibraries.concat(agencyLibraries);
          })
        );
      })
    ),
    {
      initialValue: [],
    }
  );

  submitAddFeed(errorDialog: TemplateRef<{ error: unknown }>) {
    if (!this.formGroup().valid) {
      return;
    }

    this.loading.set(true);

    const fields = this.formGroup().controls;
    this.service
      .editFeed(this.clientId(), this.feedToEdit()._id, {
        feedUrl: fields.feedUrl.value,
        name: fields.name.value,
        themeId: fields.themeId.value,
        coenCampaignId: fields.campaignId.value,
        notificationRecipients: fields.notificationEmail.value
          .split(/\s*,\s*/)
          .filter((value) => value.trim().length > 0),
        renderModularSlides: fields.modularSlideEnabled.value,
        assetLibraryId: this.optionalString(
          fields.assetLibraryId,
          this.feedToEdit().settings.assetLibraryId
        ),
        sourceLanguage: this.optionalString(
          fields.sourceLanguage,
          this.feedToEdit().settings.sourceLanguage
        ),
        outputLanguage: this.optionalString(
          fields.outputLanguage,
          this.feedToEdit().settings.outputLanguage
        ),
      })
      .pipe(
        finalize(() => this.loading.set(false)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: (feed: FeedView) => this.feedUpdated.emit(feed),
        error: (error: HttpErrorResponse) => {
          this.dialog.open(errorDialog, {
            data: {
              error: error.error,
            },
          });
        },
      });
  }

  private optionalString(
    field: FormControl<string | null>,
    originalValue: string | undefined
  ): string | undefined {
    const value = field.value;
    if (value === null) {
      return originalValue ? '' : undefined;
    }
    if (value.trim().length <= 0) {
      return originalValue ? '' : undefined;
    }
    return value;
  }
}
