<form class="bulk-form" [formGroup]="form">
  <div class="bulk-add-users-container">
    <div class="add-user-button-container">
      <button mat-flat-button (click)="addUserRow()" color="primary">
        ➕ Add User
      </button>
    </div>
    <app-user-bulk-table [form]="usersForm" />
  </div>

  @if (clientsAgencies$ | async) {
    <div class="user-access">
      <app-user-access-form
        [form]="accessForm"
        [clientsAgencies$]="clientsAgencies$"
      />
    </div>
  }

  <div class="create-users-container">
    <button
      [disabled]="form.invalid"
      mat-flat-button
      (click)="openConfirmSaveUsers()"
      color="primary"
    >
      Create Users
    </button>
  </div>
</form>
